import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Card } from 'components/common/Card/Card';
import { Typography, Space, Button, Row, Col } from 'antd';
import Blob from '@app/components/common/Blob/Blob';
import * as S from './Payment.styles';
import { RocketOutlined } from '@ant-design/icons';

type Status = {
  status: string;
  status_code: number;
  status_text: string;
};
type Transactions = {
  customer: undefined;
  result: Status;
  transactions: TransactionObject[];
};

export type PaymentDetailResponse = {
  list: Transactions[];
  merchant: {
    project: number;
    vendor: number;
  };
  result: Status;
};

type TransactionObject = {
  customer: unknown;
  result: unknown;
  transaction: TransactionDetails;
};

type TransactionDetails = {
  amount: string;
  currency: string;
  date: string;
  invoice_ref: string;
  order_no: string;
  payment_data: unknown;
  payment_type: string;
  status: string;
  status_code: string;
  test_mode: string;
  tid: string;
  txn_secret: string;
};

const PayFailurePage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <S.Wrapper>
      <S.BackgroundWrapper>
        <S.ContainerWrapper>
          <Blob />
          <PageTitle>{t('common.payFailure')}</PageTitle>
          <Card padding={20} style={{ width: 'max-content', textAlign: 'center' }}>
            <Space direction={'vertical'}>
              <Typography.Text style={{ color: 'var(--ant-error-color)', display: 'block' }}>
                {t('common.payFailure')}
              </Typography.Text>

              <Row gutter={20} style={{ marginTop: 20 }}>
                <Col span={24}>
                  <Button href={process.env.REACT_APP_ASSETS_BUCKET} icon={<RocketOutlined />}>
                    {t('buttons.about')}
                  </Button>
                </Col>
              </Row>
            </Space>
          </Card>
        </S.ContainerWrapper>
      </S.BackgroundWrapper>
    </S.Wrapper>
  );
};

export default PayFailurePage;
