import { httpApi } from '@app/api/http.api';
import { GetUserClaimsByCreditorResponse } from './user.api';
import { PaymentDetailResponse } from '@app/pages/payment/PaySuccessPage';

export interface GetPayoutsRequest {
  paymentDate?: {
    gte: Date;
    lte: Date;
  };
}

export interface GetPayoutsResponse extends GetUserClaimsByCreditorResponse {
  reference: string;
  creditor: unknown;
  debtor: unknown;
  incomingPaymentsTotal?: number;
  feesTotal?: number;
  grossFeesTotal?: number;
  grossCostsTotal?: number;
  interestTotal?: number;
  grossInterestTotal?: number;
  debtCollectorFeesTotal?: number;
  grossDebtCollectorFeesTotal?: number;
  toRecoverTotal?: number;
  paidOutTotal?: number;
  payoutReference?: string;
  payoutFileUrl?: string;
  payoutDate?: string;
}

type PAYMENT_PARAMS = {
  order_no: string;
  currency: string;
  amount: number;
};

export const getPayouts = (getPayoutsPayload: GetPayoutsRequest): Promise<GetPayoutsResponse> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const parameters: any = {};
  parameters['paymentDate[lte]'] = getPayoutsPayload.paymentDate?.lte;
  parameters['paymentDate[gte]'] = getPayoutsPayload.paymentDate?.gte;

  return httpApi
    .get<GetPayoutsResponse>('payments/payouts', {
      params: parameters ? parameters : null,
    })
    .then(({ data }) => data);
};

export const generatePaymentPage = (orderNumber: string, currency: string, paymentAmount: number) => {
  if (!orderNumber || !paymentAmount) {
    return;
  }

  const parameters: PAYMENT_PARAMS = {
    order_no: orderNumber ?? '',
    currency: currency ?? 'EUR',
    amount: paymentAmount,
  };

  return httpApi
    .post('payments/initiate-payment', parameters)
    .then(({ data }) => data)
    .catch((error) => console.error('error: ', error));
};

export const getPaymentStatus = (orderNumber: string): Promise<PaymentDetailResponse> => {
  return httpApi
    .post('payments/payment-status', { ref: orderNumber })
    .then(({ data }) => data)
    .catch((error) => console.error('error: ', error));
};
