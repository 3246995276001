import { httpApi } from '@app/api/http.api';
import { IntegrationSoftware } from '@app/domain/IntegrationModel';

export interface CreateIntegrationRequest {
  software: IntegrationSoftware;
  token?: string;
  userIdentifier?: string;
  organizationIdentifier?: string;
  customEmailAddress?: string;
}

export interface UpdateIntegrationRequest {
  id: string;
  software?: IntegrationSoftware;
  token?: string;
  userIdentifier?: string;
  customEmailAddress?: string;
  daysDueToFetchForFirstReminder?: number;
  daysDueToFetchForSecondReminder?: number;
  daysDueToFetchForThirdReminder?: number;
}

export interface CreateIntegrationResponse {
  createdAt: string;
  id: string;
  software: IntegrationSoftware;
  token?: string;
  updatedAt: string;
  userIdentifier?: string;
  customEmailAddress?: string;
  customEmailAddressSelector?: string;
  isCustomEmailAddressVerified?: boolean;
  customEmailAddressKey?: string;
  daysDueToFetchForFirstReminder?: number;
  daysDueToFetchForSecondReminder?: number;
  daysDueToFetchForThirdReminder?: number;
}

export interface UpdateIntegrationResponse {
  createdAt: string;
  id: string;
  software: IntegrationSoftware;
  token?: string;
  updatedAt: string;
  userIdentifier?: string;
  customEmailAddress?: string;
  customEmailAddressSelector?: string;
  customEmailAddressKey?: string;
  isCustomEmailAddressVerified?: boolean;
  daysDueToFetchForFirstReminder?: number;
  daysDueToFetchForSecondReminder?: number;
  daysDueToFetchForThirdReminder?: number;
}

export type GetIntegrationsResponse = Array<CreateIntegrationResponse>;

export const createIntegration = (
  createIntegrationPayload: CreateIntegrationRequest,
): Promise<CreateIntegrationResponse> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  return httpApi
    .post('integrations', {
      ...createIntegrationPayload,
    })
    .then(({ data }) => data);
};

export const updateIntegration = (
  updateIntegrationPayload: UpdateIntegrationRequest,
): Promise<UpdateIntegrationResponse> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  return httpApi
    .patch('integrations/' + updateIntegrationPayload.id, {
      ...updateIntegrationPayload,
    })
    .then(({ data }) => data);
};

export const getIntegrations = (): Promise<CreateIntegrationResponse> => {
  return httpApi.get('integrations').then(({ data }) => data);
};
