export enum IntegrationSoftware {
  Lexoffice = 'Lexoffice',
  SevDesk = 'SevDesk',
  FastBill = 'FastBill',
  BuchhaltungsButler = 'BuchhaltungsButler',
  Sage = 'Sage',
  ZohoBooks = 'ZohoBooks',
  Odoo = 'Odoo',
  Chargebee = 'Chargebee',
  Datev = 'Datev',
  Billomat = 'Billomat',
  Billbee = 'Billbee',
  Easybill = 'Easybill',
  Buhl = 'Buhl',
  Stripe = 'Stripe',
  Xentral = 'Xentral',
  Custom = 'Custom',
}

export const IntegrationSoftwareWithExternalLink = [
  IntegrationSoftware.Buhl,
  IntegrationSoftware.ZohoBooks,
  IntegrationSoftware.Stripe,
];

export const IntegrationSoftwareWithExternalLinkAndSaveButton = [IntegrationSoftware.Stripe];

export const INTEGRATION_PARTNER_URLS = new Map<IntegrationSoftware, string>([
  [IntegrationSoftware.Buhl, 'https://app.meinbuero.de/app/all/show/64f9d95705ff704a6fa4227d'],
  [
    IntegrationSoftware.ZohoBooks,
    'https://accounts.zoho.eu/oauth/v2/auth?scope=ZohoBooks.invoices.READ,ZohoBooks.settings.READ,ZohoBooks.customerpayments.READ&access_type=offline&client_id=1000.IBLXWDLU5BVHCUB9IZEBS592PMJVKF&state=debtist-zoho&prompt=consent&response_type=code&redirect_uri=' +
      process.env.REACT_APP_PUBLIC_URL +
      '/bridge',
  ],
  [IntegrationSoftware.Stripe, 'https://dashboard.stripe.com/dashboard'],
]);

export type IntegrationModel = {
  createdAt: string;
  id: string;
  software: IntegrationSoftware;
  updatedAt: string;
  token?: string;
  userIdentifier?: string;
  customEmailAddress?: string;
  customEmailAddressSelector?: string;
  customEmailAddressKey?: string;
  isCustomEmailAddressVerified?: boolean;
  daysDueToFetchForFirstReminder?: number;
  daysDueToFetchForSecondReminder?: number;
  daysDueToFetchForThirdReminder?: number;
};
