import React from 'react';
import { Col, Row, Typography } from 'antd';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useAppSelector } from '@app/hooks/reduxHooks';
import * as S from './ProfileDropdown.styles';
import { Popover } from '@app/components/common/Popover/Popover';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from '@app/components/common/Avatar/Avatar';

export const ProfileDropdown: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);

  return user ? (
    <Popover content={<ProfileOverlay />} trigger="click">
      <S.ProfileDropdownHeader as={Row} gutter={[10, 10]} align="middle">
        <Col>
          <Typography.Text title={user.businessName || user.email} style={{ maxWidth: '16vw' }} ellipsis>{`${
            user.businessName || user.email
          }`}</Typography.Text>
        </Col>
        <Col>
          <S.ImgWrapper>
            <Avatar src={user?.imgUrl || <UserOutlined />} alt="User" shape="circle" size={'default'} />
          </S.ImgWrapper>
        </Col>
      </S.ProfileDropdownHeader>
    </Popover>
  ) : null;
};
