import { LeftOutlined } from '@ant-design/icons';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useResponsive } from '@app/hooks/useResponsive';
import { slideIn } from '@app/styles/animation';
import { Col, Row, Tabs, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { PageTitle } from '../common/PageTitle/PageTitle';
import { Card } from '../common/Card/Card';
import { profileNavData } from '@app/constants/profileNavData';

const StyledTabs = styled(Tabs)`
  .ant-tabs-tab {
    padding-left: 5px !important; // override the left padding
    margin: 0 !important;
    padding: 15px 24px !important;
    transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
    cursor: pointer;
    user-select: none;
    min-width: 245.2px;
  }

  .ant-tabs-tab-active {
    transition: all 3s ease;
    background-color: rgba(15, 130, 224, 0.3);

    .ant-typography {
      color: var(--primary-color) !important;
    }

    &:hover {
      animation: none;
    }
  }
`;

const ProfileLayout: React.FC = () => {
  const { t } = useTranslation();
  const { isTablet: isTabletOrHigher, mobileOnly } = useResponsive();
  const location = useLocation();
  const navigate = useNavigate();
  const [tab, setTab] = useState<string>(() => {
    const initialPath = location.pathname.split('/').pop();
    const matchedTab = profileNavData.find((item) => item.href === initialPath);
    return matchedTab ? matchedTab.href : profileNavData[0].href;
  });

  useEffect(() => {
    const path = location.pathname.split('/').pop();
    const matchedTab = profileNavData.find((item) => item.href === path);
    if (matchedTab) {
      setTab(matchedTab.href);
    }
  }, [location.pathname]);

  const onChange = (key: string) => {
    const selectedTab = profileNavData.find((item) => item.id.toString() === key);
    if (selectedTab) {
      setTab(selectedTab.href);
      navigate(`/profile/${selectedTab.href}`);
    }
  };

  const { isTablet } = useResponsive();

  const isTitleShown = isTabletOrHigher || (mobileOnly && location.pathname === '/profile');
  const isMenuShown = isTabletOrHigher || (mobileOnly && location.pathname !== '/profile');
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const animationTimeout = setTimeout(() => {
      setAnimate(true);
    }, 600);

    return () => clearTimeout(animationTimeout);
  }, []);

  useEffect(() => {
    isTablet && location.pathname === '/profile' && navigate('personal-info');
  }, [isTablet, location.pathname, navigate]);

  return (
    <>
      <Btn hidden={isTitleShown} icon={<LeftOutlined />} type="text" onClick={() => navigate('/profile')}>
        {t('common.back')}
      </Btn>

      <Row gutter={[30, 30]}>
        <AnimatedCol hidden={!isMenuShown || !animate} xs={24} className={animate ? 'slide-in' : ''}>
          <Row gutter={[30, 30]}>
            <PageTitle>{t('profile.nav.personalInfo.title')}</PageTitle>
            <Col md={24} xl={24}>
              <Card>
                <StyledTabs
                  onChange={onChange}
                  activeKey={profileNavData.find((item) => item.href === tab)?.id.toString()}
                  defaultActiveKey={tab}
                  tabPosition={mobileOnly ? 'top' : 'left'}
                  items={profileNavData.map((item, i) => {
                    const id = String(i + 1);
                    return {
                      key: id,
                      label: !item.disabled ? (
                        <>
                          {item.icon}
                          <Typography.Text>{t(item.name)}</Typography.Text>
                        </>
                      ) : null,
                      children: item.children,
                      href: item.href,
                    };
                  })}
                  tabBarStyle={{
                    minWidth: '15%',
                  }}
                ></StyledTabs>
              </Card>
            </Col>
          </Row>
        </AnimatedCol>
      </Row>
    </>
  );
};

const AnimatedCol = styled(Col)`
  &.slide-in {
    animation: ${slideIn} 0.5s ease-out;
  }
`;

const Btn = styled(Button)`
  font-size: 1rem;
  margin-bottom: 1rem;
  font-weight: 600;
  padding: 0;
  height: unset;
  color: var(--secondary-color);
`;

export default ProfileLayout;
