import { media } from '@app/styles/themes/constants';
import { Typography } from 'antd';
import { Select as CommonSelect } from '@app/components/common/selects/Select/Select';
import styled from 'styled-components';

export const Description = styled(Typography.Text)`
  display: block;
  font-size: 0.75rem;
  margin-bottom: 1rem;

  @media only screen and ${media.md} {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
`;

export const Select = styled(CommonSelect)`
  max-width: 320px;
`;

export const FormContent = styled.div`
  margin: 1.25rem 0.5rem;
`;
