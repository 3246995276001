import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { Card } from 'components/common/Card/Card';
import { Skeleton, Typography, Space, Button, Row, Col } from 'antd';
import Blob from '@app/components/common/Blob/Blob';
import * as S from './Payment.styles';
import { useParams } from 'react-router-dom';
import { getPaymentStatus } from '@app/api/payments.api';
import { RocketOutlined } from '@ant-design/icons';
import { createClaimActivityPublic } from '@app/api/claims.api';

export type PaymentDetailResponse = {
  status?: string;
  status_code?: number;
  status_text?: string;
};

const PaySuccessPage: React.FC = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [paymentDetails, setPaymentDetails] = useState<PaymentDetailResponse | null>(null);

  const { claimReference, claimId } = useParams();

  useEffect(() => {
    if (!paymentDetails) {
      setIsLoading(true);
      const getPaymentStatusDetails = async () => {
        let response: PaymentDetailResponse;
        if (claimReference) {
          try {
            response = await getPaymentStatus(claimReference);
            if (response.status === 'SUCCESS') {
              setPaymentDetails(response);

              if (claimId && claimReference) {
                try {
                  await createClaimActivityPublic({
                    claimId: claimId,
                    claimReference: claimReference,
                    activity: {
                      activityType: 'Response',
                      result: 'ReachedPromisedToPay',
                      isPlanned: false,
                    },
                  });
                } catch (e) {}
              }
            }
          } catch (error) {
            console.error('error:', error);
          }
        }
        setIsLoading(false);
      };

      getPaymentStatusDetails();
    }
  }, [claimReference, claimId, paymentDetails]);

  const payRow = (text1: string, text2: string | undefined) => {
    return (
      <Row gutter={25} style={{ marginTop: 20 }}>
        <Col span={12} style={{ textAlign: 'left' }}>
          <Typography.Text>{text1}</Typography.Text>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Typography.Text>{`${text2}`}</Typography.Text>
        </Col>
      </Row>
    );
  };
  return (
    <S.Wrapper>
      <S.BackgroundWrapper>
        <S.ContainerWrapper>
          <Blob />
          <PageTitle>{t('common.paySuccess')}</PageTitle>
          <Card padding={20} style={{ width: 'max-content', textAlign: 'center' }}>
            <Space direction={'vertical'}>
              <Skeleton loading={isLoading}>
                <Typography.Text style={{ color: 'var(--ant-success-color)', display: 'block', minWidth: 380 }}>
                  {t('common.paySuccess')}
                </Typography.Text>

                {payRow(t('pay.paymentReference'), claimReference)}

                <Row gutter={20} style={{ marginTop: 20 }}>
                  <Col span={24}>
                    <Button href={process.env.REACT_APP_ASSETS_BUCKET} icon={<RocketOutlined />}>
                      {t('buttons.about')}
                    </Button>
                  </Col>
                </Row>
              </Skeleton>
            </Space>
          </Card>
        </S.ContainerWrapper>
      </S.BackgroundWrapper>
    </S.Wrapper>
  );
};

export default PaySuccessPage;
