import React from 'react';
import { Col, Row } from 'antd';
import { PaymentHistory } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/PaymentHistory/PaymentHistory';

const PaymentsPage: React.FC = () => {
  return (
    <Row gutter={[0, 30]} style={{ padding: 20 }}>
      <Col span={24}>
        <PaymentHistory />
      </Col>
    </Row>
  );
};

export default PaymentsPage;
