import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

const ModalLayout = React.lazy(() => import('@app/components/layouts/ModalLayout/ModalLayout'));
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
const BridgeLayout = React.lazy(() => import('@app/components/layouts/BridgeLayout/BridgeLayout'));

import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfileLayout from '@app/components/profile/ProfileLayout';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';
import DashboardPage from '@app/pages/DashboardPages/DashboardPage';
import PayPage from '@app/pages/payment/PayPage';
import PaySuccessPage from '@app/pages/payment/PaySuccessPage';
import PayFailurePage from '@app/pages/payment/PaymentFailurePage';

const NewsFeedPage = React.lazy(() => import('@app/pages/NewsFeedPage'));
const ClaimsDebtCollectionPage = React.lazy(() => import('@app/pages/ClaimsDebtCollectionPage'));
const ClaimsRemindersPage = React.lazy(() => import('@app/pages/ClaimsRemindersPage'));
const ContractPage = React.lazy(() => import('@app/pages/ContractPage'));
const ClaimDetailsPage = React.lazy(() => import('@app/pages/ClaimDetailsPage'));
const ChartsPage = React.lazy(() => import('@app/pages/ChartsPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const ClaimFormPage = React.lazy(() => import('@app/pages/ClaimFormPage'));
const GoogleMaps = React.lazy(() => import('@app/pages/maps/GoogleMapsPage/GoogleMapsPage'));
const LeafletMaps = React.lazy(() => import('@app/pages/maps/LeafletMapsPage/LeafletMapsPage'));
const ReactSimpleMaps = React.lazy(() => import('@app/pages/maps/ReactSimpleMapsPage/ReactSimpleMapsPage'));
const PigeonsMaps = React.lazy(() => import('@app/pages/maps/PigeonsMapsPage/PigeonsMapsPage'));
const Logout = React.lazy(() => import('./Logout'));

export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

const Dashboard = withLoading(DashboardPage);
const NewsFeed = withLoading(NewsFeedPage);
const ClaimForm = withLoading(ClaimFormPage);

const ClaimsDebtCollection = withLoading(ClaimsDebtCollectionPage);
const ClaimsReminders = withLoading(ClaimsRemindersPage);
const ClaimDetails = withLoading(ClaimDetailsPage);
const Charts = withLoading(ChartsPage);

const Contract = withLoading(ContractPage);

// Maps
const Google = withLoading(GoogleMaps);
const Leaflet = withLoading(LeafletMaps);
const ReactSimple = withLoading(ReactSimpleMaps);
const Pigeons = withLoading(PigeonsMaps);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

const BridgeLayoutFallback = withLoading(BridgeLayout);
const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

const ModalLayoutFallback = withLoading(ModalLayout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={protectedLayout}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="apps">
            <Route path="feed" element={<NewsFeed />} />
          </Route>
          <Route path="new">
            <Route path="claim" element={<ClaimForm />} />
          </Route>
          <Route path="/" element={<Navigate to="/claims/debtcollection" replace />} />
          <Route path="/claims" element={<Navigate to="/claims/debtcollection" replace />} />
          <Route path="/claims/debtcollection" element={<ClaimsDebtCollection />} />
          <Route path="/claims/reminders" element={<ClaimsReminders />} />
          <Route path="claims/:claimId" element={<ClaimDetails />} />
          <Route path="charts" element={<Charts />} />
          <Route path="maps">
            <Route path="google-maps" element={<Google />} />
            <Route path="leaflet-maps" element={<Leaflet />} />
            <Route path="react-simple-maps" element={<ReactSimple />} />
            <Route path="pigeon-maps" element={<Pigeons />} />
          </Route>
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          <Route path="profile" element={<ProfileLayout />}>
            <Route path="personal-info" element={<ProfileLayout />} />
            <Route path="settings" element={<ProfileLayout />} />
            <Route path="integration-settings" element={<ProfileLayout />} />
            <Route path="notification" element={<ProfileLayout />} />
            <Route path="account" element={<ProfileLayout />} />
            <Route path="appearance" element={<ProfileLayout />} />
            <Route path="integration-email" element={<ProfileLayout />} />
            <Route path="payments" element={<ProfileLayout />} />
          </Route>
        </Route>
        <Route path="/bridge" element={<BridgeLayoutFallback />} />
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/contract" element={<MainLayout />}>
          <Route index element={<Contract />} />
        </Route>
        <Route path="/pay" element={<ModalLayoutFallback />}>
          <Route index element={<PayPage />} />
        </Route>
        <Route path="/pay/:claimId/:claimReference/" element={<ModalLayoutFallback />}>
          <Route index element={<PayPage />} />
        </Route>
        <Route path="/pay-success/*" element={<ModalLayoutFallback />}>
          <Route index element={<PaySuccessPage />} />
        </Route>
        <Route path="/pay-success/:claimReference/" element={<ModalLayoutFallback />}>
          <Route index element={<PaySuccessPage />} />
        </Route>
        <Route path="/pay-success/:claimReference/:claimId/" element={<ModalLayoutFallback />}>
          <Route index element={<PaySuccessPage />} />
        </Route>
        <Route path="/pay-failure/*" element={<ModalLayoutFallback />}>
          <Route index element={<PayFailurePage />} />
        </Route>
        <Route path="/pay-failure/:claimReferencePrefix/*" element={<ModalLayoutFallback />}>
          <Route index element={<PayFailurePage />} />
        </Route>
        <Route path="/pay/:claimId/:claimReferencePrefix/:claimReference/" element={<ModalLayoutFallback />}>
          <Route index element={<PayPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
