import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { BusinessNameItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/BusinessNameItem/BusinessNameItem';
import { ContactNameItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/ContactNameItem/ContactNameItem';
import { EmailItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/EmailItem/EmailItem';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { Dates } from '@app/constants/Dates';
import { notificationController } from '@app/controllers/notificationController';
import { PaymentCard } from '@app/interfaces/interfaces';
import { ProfileInfo } from '../../../ProfileInfo/ProfileInfo';

interface PersonalInfoFormValues {
  contactName?: string;
  country?: string;
  city?: string;
  address2: string;
  nickName: string;
  address1: string;
  sex?: string;
  language?: string;
  zipcode: string;
  businessName: string;
  phone: string;
  email: string;
}

const initialPersonalInfoValues: PersonalInfoFormValues = {
  businessName: '',
  contactName: '',
  nickName: '',
  language: undefined,
  phone: '',
  email: '',
  country: undefined,
  city: undefined,
  address1: '',
  address2: '',
  zipcode: '',
};

export const PersonalInfo: React.FC = () => {
  const user = useAppSelector((state) => state.user.user);

  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const userFormValues = useMemo(
    () =>
      user
        ? {
            businessName: user.businessName,
            contactName: user.contactName,
            email: user.email,
            phone: user.phone,
            nickname: user.username,
            birthday: user.birthday && Dates.getDate(user.birthday),
            language: user.lang,
            country: user.country,
            city: user.city,
            address1: user.address1,
            address2: user?.address2,
            zipcode: user.zipcode,
          }
        : initialPersonalInfoValues,
    [user],
  );

  const [form] = BaseButtonsForm.useForm();

  const { t } = useTranslation();

  const onFinish = useCallback(
    (values: PaymentCard) => {
      // todo dispatch an action here
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setFieldsChanged(false);
        notificationController.success({ message: t('common.success') });
        console.log(values);
      }, 1000);
    },
    [t],
  );

  return (
    <BaseButtonsForm
      form={form}
      name="info"
      loading={isLoading}
      initialValues={userFormValues}
      isFieldsChanged={isFieldsChanged}
      setFieldsChanged={setFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      onFinish={onFinish}
      style={{
        padding: 20,
      }}
    >
      <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
        <Col xs={24} md={24} xl={12}>
          <ProfileInfo profileData={user} />
          <BusinessNameItem />
          <ContactNameItem />
        </Col>
      </Row>
      <Row gutter={{ xs: 10, md: 15, xl: 30 }}>
        <Col xs={24} md={24} xl={12}>
          <BaseButtonsForm.Title style={{ marginTop: 20 }}>
            {t('profile.nav.personalInfo.contactInfo')}
          </BaseButtonsForm.Title>
          <EmailItem verified={user?.isVerified} />
        </Col>
      </Row>
    </BaseButtonsForm>
  );
};
