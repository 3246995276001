import { DownOutlined, DownloadOutlined, UserOutlined } from '@ant-design/icons';
import { httpApi } from '@app/api/http.api';
import { GetPayoutsResponse, getPayouts } from '@app/api/payments.api';
import { useAppSelector } from '@app/hooks/reduxHooks';
import { readToken } from '@app/services/localStorage.service';
import { sleep } from '@app/utils/utils';
import { number as numberFormatter } from '@json2csv/formatters';
import { Parser } from '@json2csv/plainjs';
import { Avatar, Col, Row, Space, Tooltip, notification } from 'antd';
import { ColumnType } from 'antd/lib/table';
import { Payment } from 'api/paymentHistory.api';
import { Button } from 'components/common/buttons/Button/Button';
import { Dates } from 'constants/Dates';
import { PaymentStatus, paymentStatuses } from 'constants/paymentStatuses';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from '../Status/Status';
import * as S from './PaymentsTable.styles';

interface Status {
  key: number;
  date: Date;
  status: PaymentStatus | undefined;
  invoices: { claimReference: string; url: string }[];
}

interface PaymentsTableProps {
  payments: Payment[];
}

const today: Date = new Date();

interface Invoice {
  claimReference: string;
  url: string;
  debtorId?: string;
  debtorName?: string;
  creditorId?: string;
  creditorName?: string;
  creditorVatId?: string;
  creditorCountry?: string;
}

type Record = {
  date: Date;
};

export const PaymentsTable: React.FC<PaymentsTableProps> = ({ payments }) => {
  const { t } = useTranslation();
  const user = useAppSelector((state) => state.user.user);
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [dataSource, setDataSource] = useState<Status[]>([]);
  const [payouts, setPayouts] = useState<GetPayoutsResponse>();
  const [currentInvoices, setCurrentInvoices] = useState<Invoice[]>([]);

  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  const handleFileClick = useCallback(
    (url: string, claimReference: string): void => {
      const authHeader = {
        headers: { Authorization: `Bearer ${readToken()}` },
      };

      const cleanClaimReference: string = claimReference?.replaceAll('/', '-');
      const urlToFetch: string | undefined = url.split('media/' + cleanClaimReference + '/').pop();

      if (urlToFetch) {
        fetch(
          httpApi.defaults.baseURL + '/claims/' + cleanClaimReference + '/file/' + encodeURIComponent(urlToFetch),
          authHeader,
        ).then((response) => {
          if (response.ok) {
            response.blob().then((blobby) => {
              const objectUrl = window.URL.createObjectURL(blobby);
              anchor.href = objectUrl;
              anchor.download = urlToFetch;
              anchor.click();

              window.URL.revokeObjectURL(objectUrl);
            });
          }
        });
      }
    },
    [anchor],
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fetchedInvoices: React.SetStateAction<Invoice[]> = [];
        const paymentToFetch = payments[activeIndex];

        if (!paymentToFetch) {
          return;
        }

        const lte = new Date(paymentToFetch.date);
        lte.setMonth(lte.getMonth() + 1);
        lte.setDate(lte.getDate() - 1);
        lte.setHours(23, 59, 59, 0);

        const gte = new Date(paymentToFetch.date);
        gte.setHours(0, 0, 0, 0);

        if (lte > today) {
          lte.setDate(today.getDate());
        }

        const fetchedPayouts: GetPayoutsResponse | Invoice[] = await getPayouts({
          paymentDate: {
            gte,
            lte,
          },
        });

        // Sort payouts by date in descending order
        fetchedPayouts.sort(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (a: any, b: any) =>
            new Date(b.payoutDate || Date.now()).getTime() - new Date(a.payoutDate || Date.now()).getTime(),
        );
        setPayouts(fetchedPayouts);

        if (Array.isArray(fetchedPayouts) && fetchedPayouts.length > 0) {
          const newInvoices: Invoice[] = [];
          fetchedPayouts.map((payout) => {
            const { debtor, creditor, payoutFileUrl, reference } = payout;

            // used for csv export
            (payout as unknown as Invoice).creditorId = creditor?.id;
            (payout as unknown as Invoice).creditorName = creditor?.businessName;
            (payout as unknown as Invoice).creditorVatId = creditor?.vatId;
            (payout as unknown as Invoice).creditorCountry = creditor?.address?.country;
            (payout as unknown as Invoice).debtorId = debtor?.id;
            (payout as unknown as Invoice).debtorName = debtor?.name;

            (payoutFileUrl?.split(', ') || [payoutFileUrl || '']).forEach((fileUrl: string) => {
              newInvoices.push({
                claimReference: reference,
                url: fileUrl,
                debtorId: debtor?.id,
                debtorName: debtor?.name,
              });
            });
          });

          setCurrentInvoices(newInvoices);
        } else {
          if (activeIndex !== 0) {
            notification.warning({
              message: t('profile.nav.payments.empty'),
              placement: 'topRight',
            });
          }

          setCurrentInvoices([]);
        }

        const statusRecords: Status[] = payments.map((payment, index) => ({
          key: index,
          date: payment.date,
          status: paymentStatuses.find((status) => status.id === payment.status),
          details: payment,
          invoices: fetchedInvoices,
        }));

        setDataSource(statusRecords);

        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [payments, t, activeIndex]);

  const parseCsv = useCallback(
    (record: Record) => {
      if (payouts) {
        const fieldsForExport = [
          { label: t('claim.id'), value: 'id' },
          { label: t('claim.title'), value: 'title' },
          { label: t('claim.reference'), value: 'reference' },
          {
            label: t('claim.originalAmountDue'),
            value: 'originalAmountDue',
          },
          { label: t('claim.currency'), value: 'currency' },
          { label: t('claim.creditorInterest'), value: 'creditorInterest' },
          { label: t('claim.payoutDate'), value: 'payoutDate' },
          { label: t('claim.feesTotal'), value: 'feesTotal' },
          { label: t('claim.grossFeesTotal'), value: 'grossFeesTotal' },
          { label: t('claim.interestTotal'), value: 'interestTotal' },
          { label: t('claim.grossInterestTotal'), value: 'grossInterestTotal' },
          { label: t('claim.debtCollectorFeesTotal'), value: 'debtCollectorFeesTotal' },
          { label: t('claim.grossDebtCollectorFeesTotal'), value: 'grossDebtCollectorFeesTotal' },
          { label: t('claim.grossCostsTotal'), value: 'grossCostsTotal' },
          { label: t('claim.toRecoverTotal'), value: 'toRecoverTotal' },
          { label: t('claim.incomingPaymentsTotal'), value: 'incomingPaymentsTotal' },
          {
            label: t('claim.paidOutTotal') + ' (' + t('claim.foreignAmount') + ')',
            value: 'paidOutTotal',
          },
          { label: t('claim.payoutReference'), value: 'payoutReference' },
          { label: t('claim.debtorId'), value: 'debtorId' },
          { label: t('claim.debtorName'), value: 'debtorName' },
          { label: t('claim.creditorId'), value: 'creditorId' },
          { label: t('claim.creditorName'), value: 'creditorName' },
          { label: t('claim.creditorCountry'), value: 'creditorCountry' },
          { label: t('claim.creditorVatId'), value: 'creditorVatId' },
        ];
        try {
          const parser = new Parser({
            withBOM: true,
            formatters: {
              number: numberFormatter(),
            },
            fields: fieldsForExport,
          });
          const csv = parser.parse(payouts);

          const url = window.URL.createObjectURL(new Blob([csv]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${Dates.format(record.date.getTime(), 'MM-YYYY')}.csv`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        } catch (err) {
          console.error(err);
        }
      }
    },
    [payouts, t],
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: ColumnType<any>[] = useMemo(() => {
    return [
      {
        title: t('profile.nav.payments.recipient'),
        dataIndex: 'details.recipient',
        key: 'recipient',
        render: () => (
          <S.RecipientWrapper>
            <S.AvatarWrapper>
              <Avatar src={<UserOutlined />} alt={'User'} shape={'circle'} size={'default'} />
            </S.AvatarWrapper>
            {user?.businessName}
          </S.RecipientWrapper>
        ),
        align: 'left',
      },
      {
        title: t('profile.nav.payments.status.title'),
        dataIndex: 'date',
        key: 'status',
        render: (date: Date) => {
          let result: string = t('profile.nav.payments.status.paid');
          if (date.getMonth() === today.getMonth()) {
            result = t('profile.nav.payments.preliminary');
          }
          return result;
        },
        align: 'center',
      },
      {
        title: t('profile.nav.payments.date'),
        dataIndex: 'date',
        key: 'date',
        render: (date: Date) => {
          return Dates.format(date.getTime(), 'MM/YYYY');
        },
        align: 'center',
      },
      {
        title: t('profile.nav.payments.invoices'),
        render: (record: { key: number }) => {
          if (record.key !== activeIndex) {
            return (
              <a key={'download'} onClick={() => setActiveIndex(record.key)}>
                <Space>
                  {t('profile.nav.payments.downloadInvoices')}
                  <DownloadOutlined />
                </Space>
              </a>
            );
          }
          const invoices = currentInvoices;

          return (
            <S.PaymentHistoryInvoiceCol>
              {!!invoices &&
                invoices.length !== 0 &&
                invoices.map((invoice, index) => {
                  const relevantPayouts = payouts?.filter((payout) => {
                    if (!payout.fileUrls) {
                      return;
                    }
                    return payout.fileUrls?.includes(invoice.claimReference);
                  });
                  // Extract references of relevant payouts
                  let payoutReferences = '';

                  if (relevantPayouts) {
                    payoutReferences = relevantPayouts.map((payout) => payout.reference).join(', ');
                  }
                  return (
                    <Tooltip placement={'top'} title={payoutReferences} key={`${invoice.url}-${index}`}>
                      <a
                        key={invoice.claimReference}
                        onClick={() => handleFileClick(invoice.url, invoice.claimReference)}
                      >
                        <Space>
                          {`${invoice.claimReference}`}
                          <DownloadOutlined />
                        </Space>
                      </a>
                    </Tooltip>
                  );
                })}

              {invoices && invoices.length === 0 && '-'}
            </S.PaymentHistoryInvoiceCol>
          );
        },
        align: 'center',
      },
      {
        title: 'Download',
        align: 'right',
        render: (record) => {
          if (record.key === activeIndex && currentInvoices && currentInvoices.length > 0) {
            return (
              <a
                href="#"
                onClick={() => {
                  parseCsv(record);
                }}
              >
                <Space>
                  {t('profile.nav.payments.download')}
                  <DownloadOutlined />
                </Space>
              </a>
            );
          }
        },
      },
    ];
  }, [t, user?.businessName, activeIndex, currentInvoices, payouts, handleFileClick, parseCsv]);

  const handleSorting = (order: 'newest' | 'oldest') => {
    setIsLoading(true);
    const sortedData = [...dataSource];
    sortedData.sort((a, b) => {
      const dateA = a.date.getTime();
      const dateB = b.date.getTime();
      return order === 'newest' ? dateB - dateA : dateA - dateB;
    });

    setDataSource(sortedData);
    setIsLoading(false);
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <S.FormControlWrapper>
            <S.TableSortDropdown
              menu={{
                items: [
                  {
                    key: 'newest',
                    label: (
                      <a
                        onClick={() => {
                          handleSorting('newest');
                        }}
                      >
                        {t('profile.nav.payments.sort.latest')}
                      </a>
                    ),
                  },
                  {
                    key: 'oldest',
                    label: (
                      <a
                        onClick={() => {
                          handleSorting('oldest');
                        }}
                      >
                        {t('profile.nav.payments.sort.oldest')}
                      </a>
                    ),
                  },
                ],
              }}
            >
              <a
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <Space>
                  {t('profile.nav.payments.sort.title')}
                  <DownOutlined />
                </Space>
              </a>
            </S.TableSortDropdown>
            {currentInvoices.length > 0 && (
              <Button
                type={'ghost'}
                onClick={async () => {
                  for (const item of currentInvoices) {
                    handleFileClick(item.url, item.claimReference);
                    await sleep(400);
                  }
                }}
              >
                {t('profile.nav.payments.downloadAll')}
              </Button>
            )}
          </S.FormControlWrapper>
        </Col>
      </Row>
      <S.PaymentHistoryTable
        loading={isLoading}
        size={'middle'}
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </>
  );
};
