import { createAction, createAsyncThunk, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { ClaimModel } from '@app/domain/ClaimModel';
import {
  createOwnClaim,
  CreateOwnClaimRequest,
  getClaimAggregate,
  GetClaimAggregateRequest,
  GetClaimAggregateResponse,
} from '@app/api/claims.api';

export interface ClaimState {
  claimsAsCreditorDunning: ClaimModel[];
  claimsAsCreditorReminders: ClaimModel[];
  claimAggregates: GetClaimAggregateResponse;
  claimCreateOwn?: ClaimModel;
}

const initialState: ClaimState = {
  claimsAsCreditorDunning: [],
  claimsAsCreditorReminders: [],
  claimAggregates: {
    total: {
      _count: 0,
      _sum: {
        totalPaid: 0,
        totalPending: 0,
        originalAmountDue: 0,
      },
    },
    open: {
      _count: 0,
      _sum: {
        totalPaid: 0,
        totalPending: 0,
        originalAmountDue: 0,
      },
    },
    claimsPaid: {
      _count: 0,
      _sum: {
        totalPaid: 0,
        totalPending: 0,
        originalAmountDue: 0,
      },
    },
    closed: {
      _count: 0,
      _sum: {
        totalPaid: 0,
        totalPending: 0,
        originalAmountDue: 0,
      },
    },
    firstEncashmentLevel: {
      _count: 0,
      _sum: {
        totalPaid: 0,
        totalPending: 0,
        originalAmountDue: 0,
      },
    },
    secondEncashmentLevel: {
      _count: 0,
      _sum: {
        totalPaid: 0,
        totalPending: 0,
        originalAmountDue: 0,
      },
    },
    thirdEncashmentLevel: {
      _count: 0,
      _sum: {
        totalPaid: 0,
        totalPending: 0,
        originalAmountDue: 0,
      },
    },
    fourthEncashmentLevel: {
      _count: 0,
      _sum: {
        totalPaid: 0,
        totalPending: 0,
        originalAmountDue: 0,
      },
    },
    reminderLevel: {
      _count: 0,
      _sum: {
        totalPaid: 0,
        totalPending: 0,
        originalAmountDue: 0,
      },
    },
    encashmentLevel: {
      _count: 0,
      _sum: {
        totalPaid: 0,
        totalPending: 0,
        originalAmountDue: 0,
      },
    },
    judicialLevel: {
      _count: 0,
      _sum: {
        totalPaid: 0,
        totalPending: 0,
        originalAmountDue: 0,
      },
    },
    paymentPlan: {
      _count: 0,
      _sum: {
        totalPaid: 0,
        totalPending: 0,
        originalAmountDue: 0,
      },
    },
    paid: {
      _count: 0,
      _sum: {
        amount: 0,
      },
    },
    paidOut: {
      _count: 0,
      _sum: {
        amount: 0,
      },
    },
  },
};

export const setClaimsAsCreditorDunning = createAction<PrepareAction<ClaimModel[]>>(
  'claim/setClaimsAsCreditorDunning',
  (newClaimsAsCreditor) => {
    return {
      payload: newClaimsAsCreditor,
    };
  },
);

export const setClaimsAsCreditorReminders = createAction<PrepareAction<ClaimModel[]>>(
  'claim/setClaimsAsCreditorReminders',
  (newClaimsAsCreditor) => {
    return {
      payload: newClaimsAsCreditor,
    };
  },
);

export const doCreateOwnClaim = createAsyncThunk(
  'claim/createOwnClaim',
  async (createOwnClaimPayload: CreateOwnClaimRequest, { dispatch }) =>
    createOwnClaim(createOwnClaimPayload).then((response) => {
      dispatch(setCreateOwnClaimResponse(response));
    }),
);

export const setCreateOwnClaimResponse = createAction<PrepareAction<ClaimModel>>('claim/createOwnClaim', (claim) => {
  return {
    payload: claim,
  };
});

export const doGetClaimAggregate = createAsyncThunk(
  'claim/doGetClaimAggregate',
  async (getClaimAggregatePayload: GetClaimAggregateRequest, { dispatch }) =>
    getClaimAggregate(getClaimAggregatePayload).then((response) => {
      dispatch(setClaimAggregate(response));
    }),
);

export const setClaimAggregate = createAction<PrepareAction<GetClaimAggregateResponse>>(
  'claim/doGetClaimAggregate',
  (newClaimsAggregate) => {
    return {
      payload: newClaimsAggregate,
    };
  },
);

export const claimSlice = createSlice({
  name: 'claim',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setClaimsAsCreditorDunning, (state, action) => {
      state.claimsAsCreditorDunning = action.payload;
    });
    builder.addCase(setClaimsAsCreditorReminders, (state, action) => {
      state.claimsAsCreditorReminders = action.payload;
    });
    builder.addCase(setClaimAggregate, (state, action) => {
      state.claimAggregates = action.payload;
    });
    builder.addCase(setCreateOwnClaimResponse, (state, action) => {
      state.claimCreateOwn = action.payload;
    });
  },
});

export default claimSlice.reducer;
