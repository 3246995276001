import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Card } from '@app/components/common/Card/Card';
import { PaymentsTable } from '@app/components/profile/profileCard/profileFormNav/nav/payments/paymentHistory/PaymentsTable/PaymentsTable';
import { useResponsive } from '@app/hooks/useResponsive';
import { getPaymentHistory, Payment as IPayment } from '@app/api/paymentHistory.api';
import * as S from './PaymentHistory.styles';
import { useAppSelector } from '@app/hooks/reduxHooks';

export const PaymentHistory: React.FC = () => {
  const [history, setHistory] = useState<IPayment[]>([]);
  const user = useAppSelector((state) => state.user.user);

  const { isTablet } = useResponsive();

  const { t } = useTranslation();

  useEffect(() => {
    if (user?.createdAt) {
      setHistory(getPaymentHistory(new Date(user.createdAt)));
    }
  }, [user?.createdAt]);

  const content = useMemo(
    () => (
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <BaseForm.Title>{t('profile.nav.payments.paymentHistory')}</BaseForm.Title>
          <BaseForm.Item>{t('profile.nav.payments.paymentHistoryDescription')}</BaseForm.Item>
        </Col>

        <Col span={24}>
          <S.ContentWrapper isEmptyHistory={history.length === 0}>
            <PaymentsTable payments={history} />
          </S.ContentWrapper>
        </Col>
      </Row>
    ),
    [history, t],
  );

  return isTablet ? content : <Card>{content}</Card>;
};
