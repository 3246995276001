import { Keyframes, keyframes } from 'styled-components';

export const slideIn: Keyframes = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;
