import { createAction, createAsyncThunk, createSlice, PrepareAction } from '@reduxjs/toolkit';
import { IntegrationModel } from '@app/domain/IntegrationModel';
import {
  createIntegration,
  CreateIntegrationRequest,
  getIntegrations,
  GetIntegrationsResponse,
  updateIntegration,
  UpdateIntegrationRequest,
} from '@app/api/integrations.api';

export interface IntegrationState {
  integrations: IntegrationModel[] | null;
}

const initialState: IntegrationState = {
  integrations: null,
};

export const doCreateIntegration = createAsyncThunk(
  'integration/createIntegration',
  async (createIntegrationPayload: CreateIntegrationRequest, { dispatch }) =>
    createIntegration(createIntegrationPayload).then((response) => {
      dispatch(setCreateIntegrationResponse(response));
    }),
);

export const doUpdateIntegration = createAsyncThunk(
  'integration/updateIntegration',
  async (updateIntegrationPayload: UpdateIntegrationRequest, { dispatch }) =>
    updateIntegration(updateIntegrationPayload).then((response) => {
      dispatch(setUpdateIntegrationResponse(response));
    }),
);

export const setCreateIntegrationResponse = createAction<PrepareAction<IntegrationModel>>(
  'integration/createIntegration',
  (integration) => {
    return {
      payload: integration,
    };
  },
);

export const setUpdateIntegrationResponse = createAction<PrepareAction<IntegrationModel>>(
  'integration/updateIntegration',
  (integration) => {
    return {
      payload: integration,
    };
  },
);

export const doGetIntegrations = createAsyncThunk('integration/doGetIntegrations', async (_: undefined, { dispatch }) =>
  getIntegrations().then((response) => {
    dispatch(setIntegrations(response));
  }),
);

export const setIntegrations = createAction<PrepareAction<GetIntegrationsResponse>>(
  'integration/doGetIntegrations',
  (newIntegrations) => {
    return {
      payload: newIntegrations,
    };
  },
);

export const integrationSlice = createSlice({
  name: 'integration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setIntegrations, (state, action) => {
      state.integrations = action.payload;
    });
  },
});

export default integrationSlice.reducer;
