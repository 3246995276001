import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';
import translationIT from './locales/it/translation.json';
import translationFR from './locales/fr/translation.json';

export const LANGUAGE_RESOURCES = {
  en: {
    translation: translationEN,
  },
  de: {
    translation: translationDE,
  },
  it: {
    translation: translationIT,
  },
  fr: {
    translation: translationFR,
  },
};

// Get browser language and fallback to 'en' if the language is not available in resources
const browserLanguage = navigator.language.split('-')[0]; // e.g., "en-US" -> "en"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const language = LANGUAGE_RESOURCES[browserLanguage] ? browserLanguage : 'en';

i18n.use(initReactI18next).init({
  resources: LANGUAGE_RESOURCES,
  lng: localStorage.getItem('lng') || language,

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
