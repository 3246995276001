import { media } from '@app/styles/themes/constants';
import { Typography } from 'antd';
import styled from 'styled-components';

export const Description = styled(Typography.Text)`
  display: block;
  font-size: 0.75rem;
  margin-bottom: 1rem;

  @media only screen and ${media.md} {
    font-size: 1rem;
    margin-bottom: 2rem;
  }
`;

export const tagStyle: React.CSSProperties = {
  height: 28,
  lineHeight: '28px',
  fontSize: 14,
  marginInlineEnd: 8,
  verticalAlign: 'middle',
  userSelect: 'none',
};

export const tagInputStyle: React.CSSProperties = {
  width: 320,
  height: 28,
  fontSize: 14,
  marginInlineEnd: 8,
  verticalAlign: 'middle',
};

export const tagPlusStyle: React.CSSProperties = {
  height: 28,
  background: 'transparent',
  borderStyle: 'dashed',
  paddingTop: 3,
  verticalAlign: 'middle',
};
