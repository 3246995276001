import React from 'react';
import styled from 'styled-components';
import { GlobalSpinner } from '@app/components/common/GlobalSpinner';

interface LoadingProps {
  size?: string;
  hidden?: boolean;
}

export const Loading: React.FC<LoadingProps> = ({ size, hidden }) => {
  return (
    <SpinnerContainer hidden={hidden}>
      <GlobalSpinner size={size} />
    </SpinnerContainer>
  );
};

const SpinnerContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
