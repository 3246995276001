import styled from 'styled-components';
import { Typography } from 'antd';
import { media } from '@app/styles/themes/constants';

interface FullnessLineProps {
  width: number;
}

export const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 2rem;
`;

export const ProfileRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  margin: 0 1rem 0 0;

  background: linear-gradient(211.49deg, #0f82e0 15.89%, #5229c5 60.97%);

  @media only screen and ${media.xl} {
    margin-bottom: 0;
  }

  & > span {
    margin: 5px;

    @media only screen and ${media.xl} {
      margin: 7px;
    }
  }
`;

export const Title = styled(Typography.Text)`
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 0rem;
  text-align: left;
  font-size: 1rem;
`;

export const Subtitle = styled(Typography.Text)`
  margin-bottom: 0;
  color: var(--text-light-color);
  text-align: left;
  font-weight: 600;
  font-size: 0.75rem;
`;

export const FullnessWrapper = styled.div`
  border-radius: 50px;
  margin-top: 0.625rem;
  background-color: rgba(var(--warning-rgb-color), 0.5);

  @media only screen and ${media.xl} {
    height: 2.5rem;
  }
`;

export const FullnessLine = styled.div<FullnessLineProps>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  padding-right: 0.625rem;
  border-radius: 50px;
  width: ${(props) => props.width}%;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  background: linear-gradient(90deg, var(--layout-sider-bg-color) 90%, var(--background-base-color) 100%);
  color: var(--text-secondary-color);

  @media only screen and ${media.xl} {
    font-size: 0.75rem;
    font-weight: 600;
    padding-right: 0.875rem;
  }
`;

export const Text = styled(Typography.Text)`
  font-size: 0.75rem;
  text-align: left;

  color: var(--text-main-color);

  @media only screen and ${media.xl} {
    font-size: 0.875rem;
    text-align: left;
  }
`;
