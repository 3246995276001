import {
  DeploymentUnitOutlined,
  DollarOutlined,
  EyeOutlined,
  NotificationOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { SettingsOverlay } from '@app/components/header/components/settingsDropdown/settingsOverlay/SettingsOverlay/SettingsOverlay';
import { AccountSettings } from '@app/components/profile/profileCard/profileFormNav/nav/account/AccountSettings';
import { IntegrationSettings } from '@app/components/profile/profileCard/profileFormNav/nav/IntegrationSettings/IntegrationSettings';
import { Notifications } from '@app/components/profile/profileCard/profileFormNav/nav/notifications/Notifications/Notifications';
import { PersonalInfo } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/PersonalInfo';
import PaymentsPage from '@app/pages/payment/PaymentsPage';
import React from 'react';
const primaryColor = 'var(--primary-color)';

interface ProfileNavItem {
  id: number;
  name: string;
  icon: React.ReactNode;
  color: 'primary' | 'error' | 'warning' | 'success';
  children: React.ReactNode;
  href: string;
  disabled: boolean;
}
export const profileNavData: ProfileNavItem[] = [
  {
    id: 1,
    name: 'profile.nav.personalInfo.title',
    icon: <UserOutlined />,
    color: 'primary',
    href: 'personal-info',
    children: <PersonalInfo />,
    disabled: false,
  },
  {
    id: 2,
    name: 'profile.nav.account.title',
    icon: <SettingOutlined />,
    color: 'primary',
    href: 'account',
    children: <AccountSettings />,
    disabled: false,
  },
  {
    id: 3,
    name: 'profile.nav.integrationSettings.title',
    icon: <DeploymentUnitOutlined />,
    color: 'primary',
    href: 'integration-settings',
    children: <IntegrationSettings />,
    disabled: false,
  },
  {
    id: 4,
    name: 'profile.nav.payments.title',
    icon: <DollarOutlined color={primaryColor} />,
    color: 'primary',
    href: 'payments',
    children: <PaymentsPage />,
    disabled: false,
  },
  {
    id: 5,
    name: 'profile.nav.appearanceSettings.title',
    icon: <EyeOutlined />,
    color: 'primary',
    href: 'appearance',
    children: <SettingsOverlay />,
    disabled: false,
  },
  {
    id: 6,
    name: 'profile.nav.notifications.title',
    icon: <NotificationOutlined />,
    color: 'primary',
    href: 'notification',
    children: <Notifications />,
    disabled: false,
  },
];
