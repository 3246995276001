import React from 'react';
import styled from 'styled-components';

const Blob = () => {
  return (
    <BlobContainer>
      <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <path d="M70.5,74.5Q50,99,33,74.5Q16,50,33,30Q50,10,70.5,30Q91,50,70.5,74.5Z" fill="#f7eee5"></path>
        <path d="M69.5,66.5Q50,83,32.5,66.5Q15,50,32.5,27Q50,4,69.5,27Q89,50,69.5,66.5Z" fill="#f3eae5"></path>
        <path d="M72.5,71Q50,92,28.5,71Q7,50,28.5,25.5Q50,1,72.5,25.5Q95,50,72.5,71Z" fill="#efe6e5"></path>
        <path d="M71,70.5Q50,91,34.5,70.5Q19,50,34.5,26Q50,2,71,26Q92,50,71,70.5Z" fill="#ebe2e5"></path>
        <path d="M73,74Q50,98,34.5,74Q19,50,34.5,27Q50,4,73,27Q96,50,73,74Z" fill="#e7dee5"></path>
        <path d="M73,74Q50,98,25,74Q0,50,25,26.5Q50,3,73,26.5Q96,50,73,74Z" fill="#e3dae5"></path>
        <path d="M74,67.5Q50,85,32,67.5Q14,50,32,27.5Q50,5,74,27.5Q98,50,74,67.5Z" fill="#dfd6e5"></path>
        <path d="M72.5,69Q50,88,25.5,69Q1,50,25.5,29.5Q50,9,72.5,29.5Q95,50,72.5,69Z" fill="#dbd2e5"></path>
        <path d="M67,73.5Q50,97,33.5,73.5Q17,50,33.5,33Q50,16,67,33Q84,50,67,73.5Z" fill="#d7cee5"></path>
        <path d="M75,67.5Q50,85,31,67.5Q12,50,31,33.5Q50,17,75,33.5Q100,50,75,67.5Z" fill="#d3cae5"></path>
        <path d="M67,71Q50,92,27.5,71Q5,50,27.5,31.5Q50,13,67,31.5Q84,50,67,71Z" fill="#cfc6e5"></path>
        <path d="M70.5,66Q50,82,29,66Q8,50,29,30Q50,10,70.5,30Q91,50,70.5,66Z" fill="#cbc2e5"></path>
        <path d="M67.5,72Q50,94,29.5,72Q9,50,29.5,32.5Q50,15,67.5,32.5Q85,50,67.5,72Z" fill="#c7bee5"></path>
        <path d="M68.5,74.5Q50,99,33.5,74.5Q17,50,33.5,32.5Q50,15,68.5,32.5Q87,50,68.5,74.5Z" fill="#c3bae5"></path>
        <path d="M68.5,73Q50,96,32.5,73Q15,50,32.5,25.5Q50,1,68.5,25.5Q87,50,68.5,73Z" fill="#bfb6e5"></path>
        <path d="M69.5,73Q50,96,31.5,73Q13,50,31.5,28.5Q50,7,69.5,28.5Q89,50,69.5,73Z" fill="#bbb2e5"></path>
        <path d="M67,71.5Q50,93,33.5,71.5Q17,50,33.5,31Q50,12,67,31Q84,50,67,71.5Z" fill="#b7aee5"></path>
        <path d="M65.5,74.5Q50,99,34,74.5Q18,50,34,30Q50,10,65.5,30Q81,50,65.5,74.5Z" fill="#b3aae5"></path>
        <path d="M69.5,72.5Q50,95,32,72.5Q14,50,32,35Q50,20,69.5,35Q89,50,69.5,72.5Z" fill="#afa6e5"></path>
        <path d="M70.5,68.5Q50,87,33.5,68.5Q17,50,33.5,33Q50,16,70.5,33Q91,50,70.5,68.5Z" fill="#aba2e5"></path>
        <path d="M69,67.5Q50,85,34,67.5Q18,50,34,33Q50,16,69,33Q88,50,69,67.5Z" fill="#a79ee5"></path>
        <path d="M67,65.5Q50,81,26.5,65.5Q3,50,26.5,32Q50,14,67,32Q84,50,67,65.5Z" fill="#a39ae5"></path>
        <path d="M69,74Q50,98,31,74Q12,50,31,29.5Q50,9,69,29.5Q88,50,69,74Z" fill="#9f96e5"></path>
        <path d="M67,72.5Q50,95,32,72.5Q14,50,32,31.5Q50,13,67,31.5Q84,50,67,72.5Z" fill="#9b92e5"></path>
        <path d="M68,68.5Q50,87,33,68.5Q16,50,33,26.5Q50,3,68,26.5Q86,50,68,68.5Z" fill="#978ee5"></path>
        <path d="M69,65.5Q50,81,27,65.5Q4,50,27,34.5Q50,19,69,34.5Q88,50,69,65.5Z" fill="#938ae5"></path>
        <path d="M71,74.5Q50,99,26,74.5Q2,50,26,27.5Q50,5,71,27.5Q92,50,71,74.5Z" fill="#8f86e5"></path>
        <path d="M71,68Q50,86,34.5,68Q19,50,34.5,26Q50,2,71,26Q92,50,71,68Z" fill="#8b82e5"></path>
        <path d="M68,68Q50,86,26,68Q2,50,26,27.5Q50,5,68,27.5Q86,50,68,68Z" fill="#877ee5"></path>
        <path d="M68,73Q50,96,33.5,73Q17,50,33.5,26.5Q50,3,68,26.5Q86,50,68,73Z" fill="#837ae5"></path>
        <path d="M73,68.5Q50,87,30.5,68.5Q11,50,30.5,32.5Q50,15,73,32.5Q96,50,73,68.5Z" fill="#7f76e5"></path>
        <path d="M75,72Q50,94,27.5,72Q5,50,27.5,25.5Q50,1,75,25.5Q100,50,75,72Z" fill="#7b72e5"></path>
        <path d="M65.5,73Q50,96,26.5,73Q3,50,26.5,31Q50,12,65.5,31Q81,50,65.5,73Z" fill="#776ee5"></path>
        <path d="M65.5,72.5Q50,95,28,72.5Q6,50,28,29Q50,8,65.5,29Q81,50,65.5,72.5Z" fill="#736ae5"></path>
        <path d="M68,67Q50,84,28,67Q6,50,28,27.5Q50,5,68,27.5Q86,50,68,67Z" fill="#6f66e5"></path>
        <path d="M69,69Q50,88,34,69Q18,50,34,33.5Q50,17,69,33.5Q88,50,69,69Z" fill="#6b62e5"></path>
        <path d="M68.5,68Q50,86,29.5,68Q9,50,29.5,27.5Q50,5,68.5,27.5Q87,50,68.5,68Z" fill="#675ee5"></path>
        <path d="M65.5,70.5Q50,91,32,70.5Q14,50,32,32Q50,14,65.5,32Q81,50,65.5,70.5Z" fill="#635ae5"></path>
        <path d="M71.5,73Q50,96,32,73Q14,50,32,31.5Q50,13,71.5,31.5Q93,50,71.5,73Z" fill="#5f56e5"></path>
        <path d="M67.5,67Q50,84,29,67Q8,50,29,29.5Q50,9,67.5,29.5Q85,50,67.5,67Z" fill="#5b52e5"></path>
        <path d="M71.5,72.5Q50,95,34,72.5Q18,50,34,33Q50,16,71.5,33Q93,50,71.5,72.5Z" fill="#574ee5"></path>
        <path d="M69,70.5Q50,91,34.5,70.5Q19,50,34.5,29.5Q50,9,69,29.5Q88,50,69,70.5Z" fill="#534ae5"></path>
      </svg>
    </BlobContainer>
  );
};

export const BlobContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  fill: var(--secondary-color);
  width: 100%;
  height: 100%;
  z-index: -1;
  animation: blobmove 30s ease-in-out infinite;
  transform-origin: 50% 50%;
  opacity: 0;

  @keyframes blobmove {
    0% {
      opacity: 0;
      transform: scale(1) translate(10px, -30px);
    }
    1% {
      opacity: 0;
      transform: scale(2.8, 2.8) translate(5vw, -2vh) rotate(10deg);
    }
    30% {
      opacity: 0.5;
      transform: scale(3.8, 3.8) translate(10vw, -13vh) rotate(165deg);
    }
    32% {
      opacity: 0.5;
      transform: scale(3.8, 3.8) translate(10vw, -13vh) rotate(165deg);
    }
    75% {
      opacity: 0.6;
      transform: scale(4) translate(-5vw, 3vh) rotate(-30deg);
    }
    77% {
      opacity: 0.6;
      transform: scale(4) translate(-5vw, 3vh) rotate(-30deg);
    }
    99% {
      opacity: 0;
      transform: scale(3) translate(-2vw, 8vh) rotate(0deg);
    }
    100% {
      opacity: 0;
      transform: scale(1) translate(10px, -30px);
    }
  }
`;

export default Blob;
