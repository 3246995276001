import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doCreateUser } from '@app/store/slices/userSlice';
import { notificationController } from '@app/controllers/notificationController';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SignUpForm.styles';
import { Option } from '@app/components/common/selects/Select/Select';
import { useLanguage } from '@app/hooks/useLanguage';
import { readUtmSource } from '@app/services/localStorage.service';

const EU_COUNTRIES = [
  'Germany',
  'Austria',
  'Switzerland',
  'United Kingdom',
  'Belgium',
  'Bulgaria',
  'Croatia',
  'Republic of Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'Netherlands',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden',
];

const EU_COUNTRIES_DE = [
  'Deutschland',
  'Österreich',
  'Schweiz',
  'Vereinigtes Königreich',
  'Belgien',
  'Bulgarien',
  'Dänemark',
  'Estland',
  'Finnland',
  'Frankreich',
  'Griechenland',
  'Ungarn',
  'Irland',
  'Italien',
  'Kroatien',
  'Lettland',
  'Litauen',
  'Luxemburg',
  'Malta',
  'Niederlande',
  'Polen',
  'Portugal',
  'Rumänien',
  'Republik Zypern',
  'Slowakei',
  'Slowenien',
  'Spanien',
  'Schweden',
  'Tschechische Republik',
];

interface SignUpFormData {
  businessName: string;
  contactName: string;
  username: string;
  password: string;
  country: string;
}

const initValues = {
  businessName: '',
  contactName: '',
  username: '',
  password: '',
  country: '',
};

export const SignUpForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(false);
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [form] = BaseForm.useForm();
  const { language } = useLanguage();
  const utmSource = readUtmSource();
  initValues.country = language === 'de' ? 'Deutschland' : 'Germany';

  const { t } = useTranslation();

  const handleSubmit = (values: SignUpFormData) => {
    setLoading(true);

    dispatch(
      doCreateUser({
        businessName: values.businessName,
        contactName: values.contactName,
        username: values.username,
        password: values.password,
        // country: values.country, // TODO: add address object to request first
        comment: 'Self-registration' + (utmSource ? ' via ' + utmSource : ''),
      }),
    )
      .unwrap()
      .then(() => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (window.dataLayer) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.dataLayer.push({
            event: 'sign_up',
            email: values.username,
          });
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (window.uetq) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          window.uetq.push('event', 'sign_up', {});
        }
        notificationController.success({
          message: t('auth.signUpSuccessMessage'),
          description: t('auth.signUpSuccessDescription'),
        });
        navigate('/auth/login');
      })
      .catch((err) => {
        notificationController.error({ message: err.message });
        setLoading(false);
      });
  };

  const countryOptions = useMemo(
    () =>
      language === 'de'
        ? EU_COUNTRIES_DE.map((country, index) => (
            <Option key={index} value={country}>
              {country}
            </Option>
          ))
        : EU_COUNTRIES.map((country, index) => (
            <Option key={index} value={country}>
              {country}
            </Option>
          )),
    [language],
  );

  return (
    <>
      <Auth.FormWrapper>
        <BaseForm
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          requiredMark="optional"
          initialValues={initValues}
        >
          <S.Title>{t('common.signUpTitle')}</S.Title>
          <S.SignUpDescription>{t('signup.signUpInfo')}</S.SignUpDescription>
          <Auth.FormItem
            name="businessName"
            label={t('common.businessName')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInput placeholder={t('common.businessName')} />
          </Auth.FormItem>
          <Auth.FormItem
            name="contactName"
            label={t('common.contactName')}
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInput placeholder={t('common.contactName')} />
          </Auth.FormItem>
          <Auth.FormItem
            name="username"
            label={t('common.businessEmail')}
            rules={[
              { required: true, message: t('common.requiredField') },
              {
                type: 'email',
                message: t('common.notValidEmail'),
              },
            ]}
          >
            <Auth.FormInput placeholder={t('common.businessEmail')} />
          </Auth.FormItem>
          <Auth.FormItem
            label={t('common.password')}
            name="password"
            rules={[{ required: true, message: t('common.requiredField') }]}
          >
            <Auth.FormInputPassword placeholder={t('common.password')} />
          </Auth.FormItem>
          <Auth.FormItem name="country" rules={[{ required: true, message: t('common.requiredField') }]}>
            <Auth.FormSelect>{countryOptions}</Auth.FormSelect>
          </Auth.FormItem>
          <Auth.ActionsWrapper>
            <BaseForm.Item noStyle>
              <Auth.FormCheckbox value={agreeToTerms} onChange={(e) => setAgreeToTerms(e.target.checked)}>
                <Auth.Text>
                  {t('signup.agree')}{' '}
                  <a href="https://www.debtist.de/privacy/" target={'_blank'} rel="noreferrer">
                    <Auth.LinkText>{t('signup.privacyOPolicy')}</Auth.LinkText>
                  </a>{' '}
                  {t('signup.agreeSuffix')}
                </Auth.Text>
              </Auth.FormCheckbox>
            </BaseForm.Item>
          </Auth.ActionsWrapper>
          <BaseForm.Item noStyle>
            <Auth.SubmitButton disabled={!agreeToTerms} type="primary" htmlType="submit" loading={isLoading}>
              {t('common.signUpButton')}
            </Auth.SubmitButton>
          </BaseForm.Item>
          <Auth.FooterWrapper>
            <Auth.Text>
              {t('signup.alreadyHaveAccount')}{' '}
              <Link to="/auth/login">
                <Auth.LinkText>{t('common.login')}</Auth.LinkText>
              </Link>
            </Auth.Text>
          </Auth.FooterWrapper>
        </BaseForm>
      </Auth.FormWrapper>
    </>
  );
};
