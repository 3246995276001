import { WithChildrenProps } from '@app/types/generalTypes';
import React from 'react';
import { Helmet } from 'react-helmet-async';

export const PageDescription: React.FC<WithChildrenProps> = ({ children }) => {
  return (
    <Helmet>
      <meta name="description" content={children as string} />
    </Helmet>
  );
};
