import React, { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import deDe from 'antd/lib/locale/de_DE';
import enUS from 'antd/lib/locale/en_US';
import GlobalStyle from './styles/GlobalStyle';
import 'typeface-montserrat';
import 'typeface-lato';
import { AppRouter } from './components/router/AppRouter';
import { useLanguage } from './hooks/useLanguage';
import { useAutoNightMode } from './hooks/useAutoNightMode';
import { usePWA } from './hooks/usePWA';
import { useThemeWatcher } from './hooks/useThemeWatcher';
import { useAppSelector } from './hooks/reduxHooks';
import { themeObject } from './styles/themes/themeVariables';
import styled from 'styled-components';
import { WifiOutlined } from '@ant-design/icons';
import { isTrackingAllowed } from './services/localStorage.service';

const App: React.FC = () => {
  const { language } = useLanguage();
  const theme = useAppSelector((state) => state.theme.theme);

  const [isOnline, setOnline] = useState(true);

  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  window.addEventListener('online', () => {
    setOnline(true);
  });

  window.addEventListener('offline', () => {
    setOnline(false);
  });

  usePWA();
  useAutoNightMode();
  useThemeWatcher();

  const enableTracking = isTrackingAllowed();
  return (
    <>
      <meta name="theme-color" content={themeObject[theme].primary} />
      <GlobalStyle />
      <HelmetProvider>
        {enableTracking && (
          <Helmet>
            <script src="https://www.debtist.de/lib/analytics.js?v=3" type="text/javascript" />
          </Helmet>
        )}
        <ConfigProvider locale={language === 'en' ? enUS : deDe}>
          <NoInternetWrapper isOnline={isOnline}>
            <div id="offline-banner">
              <WifiOutlined />
              <span>No Internet Connection</span>
            </div>
            <AppRouter />
          </NoInternetWrapper>
        </ConfigProvider>
      </HelmetProvider>
    </>
  );
};

const NoInternetWrapper = styled.div<{ isOnline: boolean }>`
  position: 'relative';

  > #offline-banner {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9999;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    transition: transform 0.5s;
    transform: translateY(${(props) => (props.isOnline ? '-200px' : '0')});
    background-color: var(--warning-color);

    > span {
      padding-left: 1rem;
      color: var(--text-sider-secondary-color);
    }
  }
`;

export default App;
