export interface Payment {
  id: number;
  date: Date;
  status: number;
}

export const getPaymentHistory = (minDate: Date): Payment[] => {
  const payments: Payment[] = [];
  let idCounter = 1;
  const currentDate = new Date();
  // Set dates to the first day of the current month
  currentDate.setDate(1);
  minDate.setDate(1);
  minDate.setHours(0, 0, 0, 0);

  // add current month
  payments.push({
    id: ++idCounter,
    date: new Date(currentDate),
    status: 1,
  });

  // go back one month at a time
  currentDate.setMonth(currentDate.getMonth() - 1);
  // 3 year limit
  while (currentDate >= minDate && idCounter < 36) {
    // Add the current date to the array
    payments.push({
      id: ++idCounter,
      date: new Date(currentDate),
      status: 1,
    });

    // Go back one month
    currentDate.setMonth(currentDate.getMonth() - 1);
  }

  return payments;
};
