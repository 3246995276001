import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import * as S from './Notifications.styles';
import { Input } from '@app/components/common/inputs/Input/Input';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { FormContent } from '@app/components/forms/StepForm/StepForm.styles';
import { Col, InputRef, Space, Tag } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { Button } from '@app/components/common/buttons/Button/Button';
import { doUpdateUser } from '@app/store/slices/userSlice';
import { tagInputStyle, tagPlusStyle, tagStyle } from './Notifications.styles';
import { UserModel } from '@app/domain/UserModel';

const EMAIL_REGEX = /^\S+@\S+\.\S+$/;

export const Notifications: React.FC = () => {
  const user: UserModel | null = useAppSelector((state) => state.user.user);

  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [operationalEmails, setOperationalEmails] = useState<string[]>([]);
  const [operationalInputVisible, setOperationalInputVisible] = useState(false);
  const [operationalInputValue, setOperationalInputValue] = useState('');
  const operationalInputRef = useRef<InputRef>(null);

  const [financialEmails, setFinancialEmails] = useState<string[]>([]);
  const [financialInputVisible, setFinancialInputVisible] = useState(false);
  const [financialInputValue, setFinancialInputValue] = useState('');
  const financialInputRef = useRef<InputRef>(null);

  const [analyticalEmails, setAnalyticalEmails] = useState<string[]>([]);
  const [analyticalInputVisible, setAnalyticalInputVisible] = useState(false);
  const [analyticalInputValue, setAnalyticalInputValue] = useState('');
  const analyticalInputRef = useRef<InputRef>(null);

  useEffect(() => {
    // onload
    if (!user) {
      return;
    }
    if (
      user.operationalRecipients &&
      Array.isArray(user.operationalRecipients) &&
      user.operationalRecipients.length > 0
    ) {
      setOperationalEmails(user.operationalRecipients);
    } else {
      setOperationalEmails([user.email]);
    }

    if (user.financialRecipients && Array.isArray(user.financialRecipients) && user.financialRecipients.length > 0) {
      setFinancialEmails(user.financialRecipients);
    }

    if (user.analyticalRecipients && Array.isArray(user.analyticalRecipients) && user.analyticalRecipients.length > 0) {
      setAnalyticalEmails(user.analyticalRecipients);
    }
  }, [user]);

  useEffect(() => {
    if (operationalInputVisible) {
      operationalInputRef.current?.focus();
    } else if (financialInputVisible) {
      financialInputRef.current?.focus();
    } else if (analyticalInputVisible) {
      analyticalInputRef.current?.focus();
    }
  }, [operationalInputVisible, financialInputVisible, analyticalInputVisible]);

  const handleClose = (removedTag: string, type: 'operational' | 'financial' | 'analytical') => {
    if (type === 'operational') {
      const newEmails = operationalEmails.filter((tag) => tag !== removedTag);
      setOperationalEmails(newEmails);
    } else if (type === 'financial') {
      const newEmails = financialEmails.filter((tag) => tag !== removedTag);
      setFinancialEmails(newEmails);
    } else if (type === 'analytical') {
      const newEmails = analyticalEmails.filter((tag) => tag !== removedTag);
      setAnalyticalEmails(newEmails);
    }
  };

  const showInput = (type: 'operational' | 'financial' | 'analytical') => {
    if (type === 'operational') {
      setOperationalInputVisible(true);
    } else if (type === 'financial') {
      setFinancialInputVisible(true);
    } else if (type === 'analytical') {
      setAnalyticalInputVisible(true);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: 'operational' | 'financial' | 'analytical',
  ) => {
    if (type == 'operational') {
      setOperationalInputValue(e.target.value);
    } else if (type === 'financial') {
      setFinancialInputValue(e.target.value);
    } else if (type === 'analytical') {
      setAnalyticalInputValue(e.target.value);
    }
  };

  const handleInputConfirm = (type: 'operational' | 'financial' | 'analytical') => {
    if (type == 'operational' && (!operationalInputValue || EMAIL_REGEX.test(operationalInputValue))) {
      if (operationalInputValue && operationalEmails.indexOf(operationalInputValue) === -1) {
        setOperationalEmails([...operationalEmails, operationalInputValue]);
      }
      setOperationalInputVisible(false);
      setOperationalInputValue('');
    } else if (type === 'financial' && (!financialInputValue || EMAIL_REGEX.test(financialInputValue))) {
      if (financialInputValue && financialEmails.indexOf(financialInputValue) === -1) {
        setFinancialEmails([...financialEmails, financialInputValue]);
      }
      setFinancialInputVisible(false);
      setFinancialInputValue('');
    } else if (type === 'analytical' && (!analyticalInputValue || EMAIL_REGEX.test(analyticalInputValue))) {
      if (analyticalInputValue && analyticalEmails.indexOf(analyticalInputValue) === -1) {
        setAnalyticalEmails([...analyticalEmails, analyticalInputValue]);
      }
      setAnalyticalInputVisible(false);
      setAnalyticalInputValue('');
    }
  };

  const handleSubmit = () => {
    if (user) {
      dispatch(
        doUpdateUser({
          userId: user.id,
          operationalRecipients: operationalEmails,
          analyticalRecipients: analyticalEmails,
          financialRecipients: financialEmails,
        }),
      );
    }
  };

  return (
    <div style={{ padding: 20 }}>
      <BaseButtonsForm.Item>
        <BaseButtonsForm.Title>{t('profile.nav.notifications.settings')}</BaseButtonsForm.Title>
      </BaseButtonsForm.Item>
      <S.Description style={{ whiteSpace: 'pre-line' }}>{t('profile.nav.notifications.description')}</S.Description>
      <BaseForm>
        <FormContent>
          <BaseForm.Item required name="operational" label={t('profile.nav.notifications.operational') + ':'}>
            <Space size={[0, 8]} wrap>
              <Space size={[0, 8]} wrap>
                {Array.isArray(operationalEmails) &&
                  operationalEmails.map((tag) => {
                    const tagElem = (
                      <Tag key={tag} closable style={tagStyle} onClose={() => handleClose(tag, 'operational')}>
                        <span>{tag}</span>
                      </Tag>
                    );
                    return tagElem;
                  })}
                {operationalInputVisible ? (
                  <Input
                    ref={operationalInputRef}
                    type="email"
                    size="small"
                    style={tagInputStyle}
                    value={operationalInputValue}
                    onChange={(e) => handleInputChange(e, 'operational')}
                    onBlur={() => handleInputConfirm('operational')}
                    onPressEnter={() => handleInputConfirm('operational')}
                  />
                ) : (
                  <Tag style={tagPlusStyle} onClick={() => showInput('operational')}>
                    <PlusOutlined /> {t('profile.nav.notifications.recipient')}
                  </Tag>
                )}
              </Space>
            </Space>
          </BaseForm.Item>

          <BaseForm.Item name="financial" label={t('profile.nav.notifications.financial') + ':'}>
            <Space size={[0, 8]} wrap>
              <Space size={[0, 8]} wrap>
                {Array.isArray(financialEmails) &&
                  financialEmails.map((tag) => {
                    const tagElem = (
                      <Tag
                        key={tag}
                        closable
                        style={{ userSelect: 'none' }}
                        onClose={() => handleClose(tag, 'financial')}
                      >
                        <span>{tag}</span>
                      </Tag>
                    );
                    return tagElem;
                  })}
                {financialInputVisible ? (
                  <Input
                    ref={financialInputRef}
                    type="email"
                    size="small"
                    style={tagInputStyle}
                    value={financialInputValue}
                    onChange={(e) => handleInputChange(e, 'financial')}
                    onBlur={() => handleInputConfirm('financial')}
                    onPressEnter={() => handleInputConfirm('financial')}
                  />
                ) : (
                  <Tag style={tagPlusStyle} onClick={() => showInput('financial')}>
                    <PlusOutlined /> {t('profile.nav.notifications.recipient')}
                  </Tag>
                )}
              </Space>
            </Space>
          </BaseForm.Item>

          <BaseForm.Item name="analytical" label={t('profile.nav.notifications.analytical') + ':'}>
            <Space size={[0, 8]} wrap>
              <Space size={[0, 8]} wrap>
                {Array.isArray(analyticalEmails) &&
                  analyticalEmails.map((tag) => {
                    const tagElem = (
                      <Tag
                        key={tag}
                        closable
                        style={{ userSelect: 'none' }}
                        onClose={() => handleClose(tag, 'analytical')}
                      >
                        <span>{tag}</span>
                      </Tag>
                    );
                    return tagElem;
                  })}
                {analyticalInputVisible ? (
                  <Input
                    ref={analyticalInputRef}
                    type="email"
                    size="small"
                    style={tagInputStyle}
                    value={analyticalInputValue}
                    onChange={(e) => handleInputChange(e, 'analytical')}
                    onBlur={() => handleInputConfirm('analytical')}
                    onPressEnter={() => handleInputConfirm('analytical')}
                  />
                ) : (
                  <Tag style={tagPlusStyle} onClick={() => showInput('analytical')}>
                    <PlusOutlined /> {t('profile.nav.notifications.recipient')}
                  </Tag>
                )}
              </Space>
            </Space>
          </BaseForm.Item>

          <BaseForm.Item>
            <Col span={12}>
              <Button block type="primary" onClick={handleSubmit}>
                {t('common.save')}
              </Button>
            </Col>
          </BaseForm.Item>
        </FormContent>
      </BaseForm>
    </div>
  );
};
