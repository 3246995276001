import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { ConfirmItemPassword } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/passwordForm/ConfirmPasswordItem/ConfirmPasswordItem';
import { NewPasswordItem } from '@app/components/profile/profileCard/profileFormNav/nav/PersonalInfo/passwordForm/NewPasswordItem/NewPasswordItem';
import { notificationController } from '@app/controllers/notificationController';
import * as S from './PasswordForm.styles';
import { doLogout, doSetNewPassword, doVerifyCurrentPassword } from '@app/store/slices/userSlice';
import { readUser } from '@app/services/localStorage.service';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { InputPassword } from '@app/components/common/inputs/InputPassword/InputPassword';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { UserUpdatePasswordResponse } from '@app/api/user.api';
import { debounce, DebouncedFunc } from 'lodash';
import Lottie from 'react-lottie';
import { UserModel } from '@app/domain/UserModel';

interface PasswordFormData {
  password: string;
  newPassword: string;
  confirmPassword: string;
}

interface VerifyResponse {
  id: string;
  username: string;
  roles: string[];
}

export const PasswordForm: React.FC = () => {
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [hasCurrentPasswordMatched, setHasCurrentPasswordMatched] = useState<boolean>(false);
  const [isUserTypingPassword, setIsUserTypingPassword] = useState<boolean>(false);
  const user: UserModel | null = readUser();
  const [passwordForm] = BaseForm.useForm();
  const animationDuration: number = 1600;
  const [isAnimationRunning, setIsAnimationRunning] = useState<boolean>(false);

  const verifyCurrentPassword: (currentPassword: string) => void = (currentPassword: string) => {
    if (currentPassword.length <= 3) {
      return;
    }

    dispatch(
      doVerifyCurrentPassword({
        username: user?.username ?? '',
        currentPassword: currentPassword,
      }),
    )
      .unwrap()
      .then((response: VerifyResponse | undefined): void => {
        if (response?.id) {
          setHasCurrentPasswordMatched(true);
        } else {
          setHasCurrentPasswordMatched(false);
        }
        passwordForm.validateFields(['password']);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedVerifyCurrentPassword: DebouncedFunc<(currentPassword: string) => void> = useCallback(
    debounce((currentPassword: string) => verifyCurrentPassword(currentPassword), 500),
    [verifyCurrentPassword],
  );

  const onFinish = (values: PasswordFormData) => {
    setLoading(true);
    dispatch(
      doSetNewPassword({
        username: user?.username ?? '',
        oldPassword: values.password,
        newPassword: values.newPassword,
      }),
    )
      .unwrap()
      .then((response: UserUpdatePasswordResponse) => {
        if (response.accessToken) {
          setLoading(false);
          setIsAnimationRunning(true);

          notificationController.success({ message: t('profile.nav.securitySettings.passwordChanged') });
          setTimeout(() => {
            dispatch(doLogout());
            setIsAnimationRunning(false);
          }, animationDuration);
        }
      })
      .catch(() => {
        notificationController.error({ message: t('login.loginFailed') });
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isFieldsChanged && !hasCurrentPasswordMatched) {
      setIsUserTypingPassword(true);
    }
  }, [isUserTypingPassword, hasCurrentPasswordMatched, setHasCurrentPasswordMatched, isFieldsChanged]);

  // Animation config
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: require('@app/assets/lottie/success-animation.json'),
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice', // no idea wtf this value is
    },
  };

  return (
    <BaseButtonsForm
      form={passwordForm}
      name="newPassword"
      requiredMark="optional"
      isFieldsChanged={isFieldsChanged}
      onFieldsChange={() => setFieldsChanged(true)}
      footer={
        <S.Btn
          disabled={isAnimationRunning}
          loading={isLoading}
          type="primary"
          style={{ maxWidth: 300 }}
          onClick={() => passwordForm.submit()}
        >
          {t('common.confirm')}
        </S.Btn>
      }
      onFinish={onFinish}
    >
      <Row gutter={{ md: 15, xl: 30 }}>
        {isAnimationRunning ? (
          <Lottie options={defaultOptions} height={150} width={150} />
        ) : (
          <>
            <Col xs={24}>
              <BaseButtonsForm.Item>
                <BaseButtonsForm.Title
                  style={{
                    marginTop: 20,
                    marginBottom: 0,
                  }}
                >
                  {t('profile.nav.securitySettings.changePassword')}
                </BaseButtonsForm.Title>
              </BaseButtonsForm.Item>
            </Col>

            <Col xs={24} md={12}>
              <BaseButtonsForm.Item
                name="password"
                label={t('profile.nav.securitySettings.currentPassword')}
                rules={[
                  {
                    required: true,
                    message: t('profile.nav.securitySettings.requiredPassword'),
                  },
                  {
                    validator: async (_, value) => {
                      if (value !== undefined && !hasCurrentPasswordMatched) {
                        return Promise.reject(t('profile.nav.securitySettings.dontMatchPassword'));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <InputPassword
                  style={{
                    borderColor: hasCurrentPasswordMatched ? 'var(--success-color)' : 'var(--border-base-color)',
                  }}
                  disabled={hasCurrentPasswordMatched}
                  onChange={(value) => {
                    const userInput = value.target.value;
                    setIsUserTypingPassword(true);
                    debouncedVerifyCurrentPassword(userInput);
                  }}
                />
              </BaseButtonsForm.Item>
            </Col>
            <Col xs={24} md={12}></Col>

            <Col xs={24} md={12}>
              <NewPasswordItem />
            </Col>

            <Col xs={24} md={12}>
              <ConfirmItemPassword />
            </Col>
          </>
        )}
      </Row>
    </BaseButtonsForm>
  );
};
