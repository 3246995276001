import { UserModel } from './UserModel';

export const GERMAN_SPEAKING_COUNTRIES = ['DEU', 'AUT', 'CHE'];

export type AddressModel = {
  addressLine1: string;
  addressLine2: string | null;
  country?:
    | 'DEU'
    | 'AUT'
    | 'CHE'
    | 'POL'
    | 'CHN'
    | 'EST'
    | 'ITA'
    | 'NLD'
    | 'FRA'
    | 'ESP'
    | 'BEL'
    | 'GBR'
    | 'LUX'
    | 'IRL'
    | 'TUR'
    | 'IND'
    | 'MEX'
    | 'ISR'
    | 'BRA'
    | 'GRC'
    | 'TUN'
    | 'USA'
    | 'DZA'
    | 'PRT'
    | 'MAR'
    | 'BGR'
    | 'BGD'
    | 'BLR'
    | 'OMN'
    | 'TWN'
    | 'DNK'
    | 'CAN'
    | 'AUS'
    | 'ZAF'
    | 'SRB'
    | 'ROU'
    | 'NGA'
    | 'EGY';
  createdAt: Date;
  id: string;
  postalcode: string;
  town: string;
  updatedAt: Date;
  user?: UserModel | null;
};
