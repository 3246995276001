import React, { useEffect, useState } from 'react';
import { Col, Divider, Input, Row } from 'antd';
import { Card } from 'components/common/Card/Card';
import { BaseButtonsForm } from '@app/components/common/forms/BaseButtonsForm/BaseButtonsForm';
import { useTranslation } from 'react-i18next';
import * as S from './IntegrationSettings.styles';
import { doGetIntegrations, doUpdateIntegration } from '@app/store/slices/integrationSlice';
import { useAppDispatch, useAppSelector } from '@app/hooks/reduxHooks';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { Button } from '@app/components/common/buttons/Button/Button';
import { useNavigate } from 'react-router-dom';
import { Loading } from '@app/components/common/Loading';
import { CheckCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { ClipboardInput } from '@app/components/common/inputs/ClipboardInput/ClipboardInput';
import { InputNumber } from '@app/components/common/inputs/InputNumber/InputNumber';
import { notificationController } from '@app/controllers/notificationController';

export const IntegrationSettingsEmail: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const currentIntegrations = useAppSelector((state) => state.integration.integrations);
  const [emailDomain, setEmailDomain] = useState(currentIntegrations?.[0].customEmailAddress?.split('@')[1]);
  const [email, setEmail] = useState(currentIntegrations?.[0]?.customEmailAddress);
  const [firstReminderDays, setFirstReminderDays] = useState(
    currentIntegrations?.[0]?.daysDueToFetchForFirstReminder || 7,
  );
  const [secondReminderDays, setSecondReminderDays] = useState(
    currentIntegrations?.[0]?.daysDueToFetchForSecondReminder || 14,
  );
  const [thirdReminderDays, setThirdReminderDays] = useState(
    currentIntegrations?.[0]?.daysDueToFetchForThirdReminder || 20,
  );

  const [isLoading, setIsLoading] = useState(false);

  const [formDefault] = BaseForm.useForm();
  const currentForm = formDefault;

  const user = useAppSelector((state) => state.user.user);

  const dispatch = useAppDispatch();

  const submitForm = async () => {
    try {
      if (currentIntegrations?.[0].id && email) {
        const domain = email.split('@')?.[1];
        if (!domain || (domain.match(/\./g) || []).length < 2) {
          notificationController.error({ message: t('profile.nav.integrationSettings.error.noSubDomain') });
          return;
        }

        setIsLoading(true);
        // update
        await dispatch(
          doUpdateIntegration({
            id: currentIntegrations?.[0]?.id,
            customEmailAddress: email,
            daysDueToFetchForFirstReminder: firstReminderDays,
            daysDueToFetchForSecondReminder: secondReminderDays,
            daysDueToFetchForThirdReminder: thirdReminderDays,
          }),
        );
        await dispatch(doGetIntegrations());

        setIsLoading(false);
      }
    } catch (error) {
      console.log('error fetching integrations', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (user) {
        try {
          await dispatch(doGetIntegrations());
        } catch (error) {
          console.log('error fetching integrations', error);
        }
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setEmail(currentIntegrations?.[0]?.customEmailAddress || '');
    setEmailDomain(currentIntegrations?.[0].customEmailAddress?.split('@')[1]);

    currentForm.setFieldsValue({
      domainKeyDKIM: currentIntegrations?.[0].customEmailAddressSelector + '._domainkey.' + emailDomain,
      domainValueDKIM: 'v=DKIM1; k=rsa; h=sha256; p=' + currentIntegrations?.[0].customEmailAddressKey,
      domainKeyCNAME: emailDomain,
      domainValueCNAME: 'eu.sparkpostmail.com',
    });
  }, [currentForm, currentIntegrations, emailDomain]);

  if (!currentIntegrations || !currentIntegrations[0]) {
    return <Loading />;
  }

  return (
    <Card>
      <BaseButtonsForm.Item>
        <BaseButtonsForm.Title>{t('profile.nav.integrationSettings.whitelabel')}</BaseButtonsForm.Title>
      </BaseButtonsForm.Item>
      <S.Description style={{ whiteSpace: 'pre-line' }}>
        {t('profile.nav.integrationSettings.whitelabelDescription')}
      </S.Description>
      <Row gutter={[30, 30]}>
        <Col span={24}>
          {currentIntegrations[0].customEmailAddress && currentIntegrations[0].isCustomEmailAddressVerified && (
            <>
              <CheckCircleFilled /> {t('profile.nav.integrationSettings.verified')}
            </>
          )}
          {currentIntegrations[0].customEmailAddress && !currentIntegrations[0].isCustomEmailAddressVerified && (
            <>
              {t('profile.nav.integrationSettings.customEmailAddressDescription')}
              <br />
              <BaseForm
                form={currentForm}
                initialValues={{
                  domainKeyDKIM: currentIntegrations[0].customEmailAddressSelector + '._domainkey.' + emailDomain,
                  domainValueDKIM: 'v=DKIM1; k=rsa; h=sha256; p=' + currentIntegrations[0].customEmailAddressKey,
                  domainKeyCNAME: emailDomain,
                  domainValueCNAME: 'eu.sparkpostmail.com',
                }}
              >
                <S.FormContent>
                  <BaseForm.Item
                    name="domainKeyDKIM"
                    label={t('profile.nav.integrationSettings.customEmailAddressHostname')}
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <ClipboardInput prefix="TXT" readOnly />
                  </BaseForm.Item>
                  <BaseForm.Item
                    name="domainValueDKIM"
                    label={t('profile.nav.integrationSettings.customEmailAddressValue')}
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <ClipboardInput readOnly />
                  </BaseForm.Item>
                  <Divider />
                  <BaseForm.Item
                    name="domainKeyCNAME"
                    label={t('profile.nav.integrationSettings.customEmailAddressHostname')}
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <ClipboardInput prefix="CNAME (optional)" readOnly />
                  </BaseForm.Item>
                  <BaseForm.Item
                    name="domainValueCNAME"
                    label={t('profile.nav.integrationSettings.customEmailAddressValue')}
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <ClipboardInput readOnly />
                  </BaseForm.Item>
                </S.FormContent>
              </BaseForm>
              <Divider />
            </>
          )}
          <BaseForm
            initialValues={{
              email: currentIntegrations?.[0]?.customEmailAddress,
              firstReminderDays: currentIntegrations?.[0]?.daysDueToFetchForFirstReminder,
              secondReminderDays: currentIntegrations?.[0]?.daysDueToFetchForSecondReminder,
              thirdReminderDays: currentIntegrations?.[0]?.daysDueToFetchForThirdReminder,
            }}
            fields={[
              {
                name: ['email'],
                value: email,
              },
              {
                name: ['firstReminderDays'],
                value: firstReminderDays,
              },
              {
                name: ['secondReminderDays'],
                value: secondReminderDays,
              },
              {
                name: ['thirdReminderDays'],
                value: thirdReminderDays,
              },
            ]}
          >
            <S.FormContent>
              {currentIntegrations && currentIntegrations[0] && (
                <>
                  <BaseForm.Item
                    name="email"
                    label={t('profile.nav.integrationSettings.email')}
                    rules={[
                      {
                        required: true,
                        type: 'email',
                        message: t('common.notValidEmail'),
                      },
                    ]}
                  >
                    <Input style={{ maxWidth: 350 }} onChange={(e) => setEmail(e.target.value)} />
                  </BaseForm.Item>

                  <BaseForm.Item
                    name="firstReminderDays"
                    label={t('profile.nav.integrationSettings.firstReminderDays')}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    style={{ display: 'inline-block', width: 180 }}
                  >
                    <InputNumber
                      style={{ maxWidth: 120 }}
                      onChange={(value) => value && setFirstReminderDays(Number(value))}
                      addonAfter={t('profile.nav.integrationSettings.days')}
                    />
                  </BaseForm.Item>
                  <BaseForm.Item
                    name="secondReminderDays"
                    label={t('profile.nav.integrationSettings.secondReminderDays')}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    style={{ display: 'inline-block', width: 180 }}
                  >
                    <InputNumber
                      style={{ maxWidth: 120 }}
                      onChange={(value) => value && setSecondReminderDays(Number(value))}
                      addonAfter={t('profile.nav.integrationSettings.days')}
                    />
                  </BaseForm.Item>
                  <BaseForm.Item
                    name="thirdReminderDays"
                    label={t('profile.nav.integrationSettings.thirdReminderDays')}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    style={{ display: 'inline-block', width: 180 }}
                  >
                    <InputNumber
                      style={{ maxWidth: 120 }}
                      onChange={(value) => value && setThirdReminderDays(Number(value))}
                      addonAfter={t('profile.nav.integrationSettings.days')}
                    />
                  </BaseForm.Item>
                </>
              )}
              <Row>
                <Col style={{ marginRight: 10 }}>
                  <Button type="default" onClick={() => navigate(-1)}>
                    {t('profile.nav.integrationSettings.back')}
                  </Button>
                </Col>
                {currentIntegrations && currentIntegrations[0] && (
                  <Col>
                    <Button
                      loading={isLoading}
                      disabled={isLoading || !email}
                      type="primary"
                      onClick={() => submitForm()}
                    >
                      {currentIntegrations?.[0].customEmailAddress === email
                        ? t('profile.nav.integrationSettings.recheck')
                        : t('profile.nav.integrationSettings.continue')}
                    </Button>
                  </Col>
                )}
              </Row>
            </S.FormContent>
          </BaseForm>
        </Col>
      </Row>
    </Card>
  );
};

export const FormContent = styled.div`
  margin: 1.25rem 0.5rem;
`;
