import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import * as S from './SiderMenu.styles';
import { sidebarNavigation, SidebarNavigationItem } from '../sidebarNavigation';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const sidebarNavFlat = sidebarNavigation.reduce(
  (result: SidebarNavigationItem[], current) =>
    result.concat(current.children && current.children.length > 0 ? current.children : current),
  [],
);

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const currentMenuItem: SidebarNavigationItem | undefined = sidebarNavFlat.find(
    ({ url }) => url === location.pathname,
  );
  const defaultSelectedKeys: string[] = currentMenuItem ? [currentMenuItem.key] : [];

  const openedSubmenu: SidebarNavigationItem | undefined = sidebarNavigation.find(
    ({ children }) => children?.some(({ url }) => url === location.pathname),
  );
  const defaultOpenKeys: string[] = openedSubmenu ? [openedSubmenu.key] : ['dataTables', 'auth'];

  return (
    <S.Menu
      mode={'inline'}
      defaultSelectedKeys={defaultSelectedKeys}
      defaultOpenKeys={defaultOpenKeys}
      onClick={() => setCollapsed(true)}
      items={sidebarNavigation.map((nav) => {
        const isSubMenu = nav.children?.length;

        return {
          key: nav.key,
          title: t(nav.title),
          label: isSubMenu ? t(nav.title) : <Link to={nav.url || ''}>{t(nav.title)}</Link>,
          icon: nav.icon,
          disabled: nav.disabled,
          children:
            isSubMenu &&
            nav.children &&
            nav.children.map((childNav) => ({
              key: childNav.key,
              icon: childNav.icon ?? null,
              label: (
                <Link className={`-${childNav.key}`} to={childNav.url || ''}>
                  {t(childNav.title)}
                </Link>
              ),
              title: t(childNav.title),
            })),
        };
      })}
    />
  );
};

export default SiderMenu;
