import React from 'react';
import * as S from './Status.styles';

interface StatusProps {
  color: string;
  text: string;
  style?: React.CSSProperties;
}

export const Status: React.FC<StatusProps> = ({ color, text, style }) => (
  <S.StatusTag style={style} color={color}>
    {text}
  </S.StatusTag>
);
