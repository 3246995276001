import { Dates } from '@app/constants/Dates';
import { LANGUAGE_RESOURCES } from '@app/i18n';
import { LanguageType } from '@app/interfaces/interfaces';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useLanguage = (): { language: LanguageType; setLanguage: (locale: LanguageType) => Promise<void> } => {
  const { i18n } = useTranslation();

  // Get browser language and fallback to 'en' if the language is not available in resources
  const browserLanguage = navigator.language.split('-')[0]; // e.g., "en-US" -> "en"
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const language = LANGUAGE_RESOURCES[browserLanguage] ? browserLanguage : 'en';

  const handleChangeLanguage = useCallback(
    async (locale: LanguageType) => {
      Dates.setLocale(locale);
      localStorage.setItem('lng', locale);
      await i18n.changeLanguage(locale);
    },
    [i18n],
  );

  useEffect(() => {
    const localLanguage = (localStorage.getItem('lng') as LanguageType) || language;

    if (i18n.language !== localLanguage) {
      handleChangeLanguage(localLanguage);
    }
  }, [handleChangeLanguage, i18n.language, language]);

  return useMemo(
    () => ({ language: i18n.language as LanguageType, setLanguage: handleChangeLanguage }),
    [handleChangeLanguage, i18n.language],
  );
};
