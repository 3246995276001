import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar } from 'antd';
import { UserModel } from '@app/domain/UserModel';
import * as S from './ProfileInfo.styles';
import { UserOutlined } from '@ant-design/icons';

interface ProfileInfoProps {
  profileData: UserModel | null;
}

export const ProfileInfo: React.FC<ProfileInfoProps> = ({ profileData }) => {
  const [fullness] = useState(100);

  const { t } = useTranslation();

  return profileData ? (
    <S.Wrapper>
      <S.ProfileRow>
        <S.ImgWrapper>
          <Avatar size={45} shape="circle" src={profileData?.imgUrl || <UserOutlined />} alt="Profile" />
        </S.ImgWrapper>
        <S.TextWrapper>
          <S.Title>{`${profileData?.name || ''}`}</S.Title>
          <S.Subtitle>{profileData?.username}</S.Subtitle>
        </S.TextWrapper>
      </S.ProfileRow>
      <S.FullnessWrapper hidden={fullness === 100}>
        <S.FullnessLine width={fullness}>{`${t('profile.fullness')} ${fullness}%`}</S.FullnessLine>
      </S.FullnessWrapper>
    </S.Wrapper>
  ) : null;
};
