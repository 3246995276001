import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { BaseForm } from '@app/components/common/forms/BaseForm/BaseForm';
import { VerificationCodeInput } from '@app/components/common/VerificationCodeInput/VerificationCodeInput';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { doVerifySecurityCode } from '@app/store/slices/userSlice';
import { notificationController } from '@app/controllers/notificationController';
import VerifyEmailImage from '@app/assets/images/verify-email.webp';
import * as Auth from '@app/components/layouts/AuthLayout/AuthLayout.styles';
import * as S from './SecurityCodeForm.styles';
import { CreateVerificationCodeResponse } from '@app/api/user.api';

interface SecurityCodeFormProps {
  onBack?: () => void;
  onFinish?: () => void;
}

export const SecurityCodeForm: React.FC<SecurityCodeFormProps> = ({ onBack, onFinish }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const navigateBack = useCallback(() => navigate(-1), [navigate]);
  const navigateForward = useCallback(() => navigate('/auth/new-password'), [navigate]);

  const [securityCode, setSecurityCode] = useState('');
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const query = window.location.search.substring(1);
    const urlParams = new URLSearchParams(query);
    const email = urlParams.get('email');

    if (!email) {
      notificationController.error({ message: t('common.notValidEmail') });
      return navigateBack();
    }
    if (securityCode.length === 6) {
      setLoading(true);
      dispatch(
        doVerifySecurityCode({
          email: email.toString(),
          code: securityCode,
        }),
      )
        .unwrap()
        .then((response: CreateVerificationCodeResponse) => {
          const verifierEncoded: string = encodeURIComponent(response.verifier);
          if (response) {
            navigate(`/auth/new-password/?uid=${response.id}&verifier=${verifierEncoded}`);
          }
        })
        .catch((err) => {
          notificationController.error({ message: err.message });
          setLoading(false);
        });
    }
  }, [t, securityCode, navigateForward, onFinish, dispatch, navigate, navigateBack]);

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" requiredMark="optional">
        <Auth.BackWrapper onClick={onBack || navigateBack}>
          <Auth.BackIcon />
          {t('common.back')}
        </Auth.BackWrapper>
        <S.ContentWrapper>
          <S.ImageWrapper>
            <Image src={VerifyEmailImage} alt="Not found" preview={false} />
          </S.ImageWrapper>
          <Auth.FormTitle>{t('securityCodeForm.title')}</Auth.FormTitle>
          <S.VerifyEmailDescription>{t('common.verifCodeSent')}</S.VerifyEmailDescription>
          {isLoading ? <Spin /> : <VerificationCodeInput autoFocus onChange={setSecurityCode} />}
          <a href="mailto:support@partner.debtist.de">
            <S.NoCodeText>{t('securityCodeForm.noCode')}</S.NoCodeText>
          </a>
        </S.ContentWrapper>
      </BaseForm>
    </Auth.FormWrapper>
  );
};
